.waves {
  position: relative;
  margin: 90px auto;
  @include mq(mobile) {
    margin: 50px auto 0px auto; }
  // &::before
  //   content: ''
  //   width: 100%
  //   height: 100px
  //   background-image: url("/img/waves_top.png")
  //   background-position: top 7px right 60px
  //   background-repeat: no-repeat
  //   position: absolute
  //   right: 0px
  //   top: -100px
  //   +mq(mobile)
  //     background-size: contain
  //     background-position: bottom right

  // &::after
  //   content: ''
  //   width: 100%
  //   height: 100px
  //   background-image: url("/img/waves_bottom.png")
  //   background-position: top -15px left -20px
  //   background-repeat: no-repeat
  //   position: absolute
  //   left: 0px
  //   bottom: -100px
  //   +mq(mobile)
  //     background-size: contain
  //     background-position: top left
  //     top: inherit
  //     height: 50px
 }  //     bottom: -50px

.waves-home {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; }

.waves-top {
  background-image: url('/img/WavesDown.svg');
  background-repeat: no-repeat;
  background-position: top 0px right 0px;
  @include mq(mobile) {
    background-size: 140%; } }
