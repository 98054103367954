// Black and outline hero titles
.hero-title {
  span {
    display: block;
    font-size: rem(120);
    letter-spacing: space(-10);
    font-weight: $extrabold;
    @include mq(mobile) {
      font-size: 15vw; }

    &.outline {
      position: relative;
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke: 1px $dark-color;
      @include ie11 {
        color: transparent;
        color: rgba(255, 255, 255, 1);
        text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;

 }        //opacity: 0.5
      &::before {
        content: attr(data-text);
        position: absolute;
        top: 4px;
        left: 4px;
        @include ie11 {
          opacity: 0.5; }
        @include mq(mobile) {
          display: none; } } } } }

// Small uppercase over section and hero titles
.section-overtitle {
  display: inline-flex;
  text-transform: uppercase;
  font-weight: $extrabold;
  font-size: rem(16);
  line-height: 1.55;
  color: $medium-color;
  @include mq(mobile) {
    font-size: rem(13); }

  &--light {
    color: $light-color; }

  &::before {
    content: '';
    display: block;
    height: 2px;
    width: 65px;
    background-color: currentColor;
    margin-top: calc(.5em + 2px);
    margin-right: 20px;
    @include mq(mobile) {
      width: 35px;
      margin-right: 15px; } } }


.section-overtitle-reverse {
  display: inline-flex;
  text-transform: uppercase;
  font-weight: $extrabold;
  font-size: rem(16);
  line-height: 1.55;
  color: $medium-color;
  @include mq(mobile) {
    font-size: rem(13); }

  &--light {
    color: $light-color; }

  &::after {
    content: '';
    display: block;
    height: 2px;
    width: 65px;
    background-color: currentColor;
    margin-top: calc(.5em + 2px);
    margin-left: 20px;
    @include mq(mobile) {
      width: 35px;
      margin-left: 15px; } } }

// Serif main section titles
.section-title {
  position: relative;
  font-family: $alternate-font;
  font-style: italic;
  font-size: rem(60);
  line-height: 1.33;
  @include mq(mobile) {
    font-size: 7.6vw;
    line-height: 1.28;
    margin-left: 0;
    padding: 0px;
    width: 100%; }

  &--line {
    line-height: 1.2;
    @include mq(tablet) {
      padding: 0px 0px 0px 45px; }
    &::before {
      position: absolute;
      right: calc(100% + 15px);
      top: .7em;
      display: block;
      content: '';
      width: calc(50vw - #{$wrapper-width / 2}); // Distance between left of wrapper and left of screen
      height: 1px;
      background-color: currentColor;
      @include mq(tablet) {
        left: -15px;
        right: inherit;
        width: 45px; } } }



  span {
    color: $primary-color; } }
