.home {

  .pushs {
    padding-bottom: 30px; }

  .waves-home {
    max-width: 100%;
    overflow: hidden;
    @include mq(mobile) {
      height: 60vh !important;
      canvas {
        height: 100% !important; } } }

  &__hero {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100vh;
    padding: 0 55px;
    overflow-x: hidden;

    &__animation {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      @include ie11 {
        height: 43.92vw; } // Ratio of 1366 * 600
      @include mq(tablet) {
        width: 200%; } }

    .section-overtitle {
      @include mq(mobile) {
        padding-top: 45px;
        color: $medium-color; } }

    &__content {
      position: relative;
      margin: auto;
      justify-content: center;
      display: flex;
      flex-direction: column; }

    &__image {
      width: 100%;
      max-width: 824px;
      margin: 0px auto;
      align-self: center;
      @include mq(mobile) {
        margin: 15px auto; } }

    &__title {
      padding-top: 10px; }

    &__scroll {
      position: relative;
      margin: 0 auto 20px;
      width: 25px;
      height: 40px;
      @include mq(mobile) {
        display: none; }

      &__animation {
        height: 100%;
        width: 100%; } } }

  &__ia {
    position: relative;
    padding: 80px 0 120px;
    overflow: hidden;
    @include mq(mobile) {
      padding: 40px 0 40px; }

    &__inner {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 50px;
      @include mq(tablet) {
        gap: 25px; }
      @include mq(mobile) {
        flex-direction: column; } }

    &__img {
      width: 40%;
      max-width: calc(1042px * 0.4);
      @include mq(mobile) {
        width: 100%;
        max-width: calc(100% - 30px); }

      img {
        display: block;
        margin-left: auto;
        width: 100%;
        max-width: 100%;
        object-fit: cover;
        @include ie11 {
          font-family: 'object-fit: cover'; }
        @include mq(tablet) {
          width: 100%;
          max-width: 100%; }
        @include mq(mobile) {
          width: 75%;
          max-width: 75%;
          margin: 0px auto; } } }

    &__content {
      width: 60%;
      max-width: calc(1042px * 0.6);
      @include mq(mobile) {
        width: 100%;
        max-width: calc(100% - 30px); }

      .section-overtitle-reverse {
        margin-bottom: 20px;
        @include mq(mobile) {
          margin-bottom: 10px; } }

      p {
        padding-top: 1rem; }

      .link {
        margin-top: 50px; } } }

  &__about {
    position: relative;
    padding: 0 0 150px;
    overflow: hidden;
    @include mq(mobile) {
      padding: 0 0 80px; }

    &__animation {
      position: absolute;
      bottom: -15%;
      left: -15%;
      height: 95%;
      width: 65%;
      @include mq(tablet) {
        display: none; } }

    &__content {
      margin-left: 50%;
      padding-left: 28px;
      padding-top: 30px;
      @include mq(tablet) {
        margin: 0;
        padding: 0; }

      .link {
        margin-top: 65px; } } }

  &__expertises {
    padding-top: 100px;
    background-color: $medium-color-light;
    @include mq(mobile) {
      padding: 45px 0px; }

    &__inner {
      display: flex;
      padding-top: 25px;
      overflow: hidden;
      @include mq(tablet) {
        flex-direction: column;
        padding-top: 0px; }

      & .half-wrapper {
        max-width: calc(1252px / 2);
        @include mq(tablet) {
          max-width: calc(100% - 30px);
          margin: 0px auto; } } }

    &__image {
      width: calc(50% + 28px);
      align-self: flex-end;
      position: relative;
      min-height: 700px;
      object-fit: cover;
      @include ie11 {
        font-family: 'object-fit: cover'; } // Hack Object-fit for IE 11
      @include mq(tablet) {
        width: 100%;
        min-height: 280px;
        margin-bottom: 30px; }
      @include mq(mobile) {
        margin-bottom: 0; } }

    .floating-number {
      margin-bottom: 30px;
      transform: translateX(-#{rem(60)}); } // Transform half of the number width

    &__title {
      position: relative;
      display: inline-block;
      height: 50px;
      font-family: $alternate-font;
      font-size: rem(38);
      font-style: italic;
      margin-bottom: 50px;
      @include mq(mobile) {
        font-size: rem(25);
        display: inline-flex;
        align-items: center;
        margin: 0px;
        height: 70px; }
      &--1,
      &--3,
      &--5 {
        margin-left: 50px;
        @include mq(mobile) {
          margin-left: 85px; }
        &::after {
          content: '';
          position: absolute;
          width: 50vw;
          right: calc(-50vw - 20px);
          top: 24px;
          height: 1px;
          background: $engineering-color;
          @include mq(mobile) {
            left: -100px;
            width: 85px;
            top: 35px;
            right: inherit; } } }
      &--2,
      &--4 {
        margin-left: 200px;
        @include mq(mobile) {
          margin-left: 30px; }
        &::before {
          content: '';
          position: absolute;
          width: 180px;
          left: -200px;
          top: 24px;
          height: 1px;
          background: red;
          @include mq(mobile) {
            right: -320px;
            left: inherit;
            top: 35px;
            width: 300px; } } } }

    &__content {
      padding-left: 100px;
      padding-bottom: 60px;
      @include mq(tablet) {
        padding-left: 0; }
      @include mq(mobile) {
        padding: 0; }

      .link {
        margin-top: 45px; } } }

  &__projects {
    padding-bottom: 275px;
    @include mq(mobile) {
      padding-bottom: 50px;
      background-size: 150%; }

    &__grid {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap; }

    &__intro {
      width: calc(50% - 14px);
      margin-top: 100px;
      @include mq(mobile) {
        width: 100%; }

      .wysiwyg {
        padding-top: 25px; }

      .link {
        margin-top: 45px; }

      .section-title--line {
        @include mq(mobile) {
          padding: 0px 0px 0px 40px;
          &::before {
            left: -15px; } } } } }

  .project-card {
    margin-top: 50px;
    width: calc(50% - 14px);
    @include mq(mobile) {
      width: 100%;
      margin: 20px 0px 20px 0px; }

    &:nth-child(even) {
      transform: translateY(55%);
      @include mq(mobile) {
        transform: none; } }
    &__title {
      padding-top: 5px !important;
      @include mq(mobile) {
        padding-top: 0px; } }

    &__image {
      width: 100%;
      max-width: 100%;
      background-color: transparent;
      img {
        width: 100%;
        height: 360px;
        object-fit: cover;
        @include ie11 {
          font-family: 'object-fit: cover'; } } }
    &__tags {
      display: flex;
      flex-wrap: wrap;
      padding-top: 5px;

      .tag {
        margin-right: 20px;
        color: $medium-color; } } }

  .icon--scroll {
    width: 26px; }

  &__scriptWidget {
    margin-bottom: 90px;
    position: relative;
    &__animation {
      position: absolute;
      bottom: 99.5%;
      right: 0;
      width: 50%;
      @include mq(mobile) {
        width: 100%; } } } }
