.offer {
  &__detail {
    display: flex;
    flex-direction: column;
    margin-top: 200px;
    @include mq(tablet) {
      margin-top: 150px; }

    .section-title {
      margin-bottom: 0px;
      line-height: 1.2;
      strong {
        color: $engineering-color; } }

    p {
      margin-top: 15px;
      line-height: 1.33; } }

  &__meta {
    margin: 10px 0px 0px 0px;
    @include mq(mobile) {
      margin: 10px 0px 20px 0px; } }

  &__img {
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.12);
    margin: 20px 0px 100px 0px;
    max-height: 430px;
    @include mq(tablet) {
      margin-bottom: 40px; }
    @include ie11 {
      font-family: 'object-fit: cover'; } // Hack Object-fit for IE 11

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; } }

  &__presentation {
    max-width: 65%;
    @include mq(tablet) {
      max-width: 100%; } }

  &__about-profile {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @include mq(tablet) {
      flex-direction: column; } }

  &__description {
    margin-top: 50px; }

  &__profile {
    max-width: 30%;
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    padding: 20px 0px 15px 0px;
    margin-bottom: auto;
    @include mq(tablet) {
      max-width: 100%;
      margin-top: 60px; }

    &__description {
      padding: 10px 15px 15px; } }

  &__process {
    margin-top: 50px;
    &__blocks {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-top: 25px; }

    &__block {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: 100px;
      justify-content: space-between;
      @include mq(tablet) {
        width: 100%;
        margin-top: 0px;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center; }

      &__icon {
        border-radius: 50%;
        overflow: hidden;
        box-shadow: 0 3px 12px rgba(0, 0, 0, 0.12);
        @include mq(tablet) {
          margin-right: 20px; }
        img {
          width: 100%;
          height: 100%; } } } }

  &__additionnal {
    margin-top: 70px; }

  &__apply {
    height: 500px;
    margin-top: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include mq(tablet) {
      margin-top: 30px; }

    &__background {
      background-color: #ffffff;
      height: 320px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-radius: 10px;
      box-shadow: 0 3px 12px rgba(0, 0, 0, 0.12);
      margin: 0;
      width: 100%;
      padding: 50px;
      @include mq(tablet) {
        height: auto;
        padding: 30px 0; }

      .section-title {
        &::before {
          width: 50vw;
          @include mq(tablet) {
            width: 10vw; } } } }

    &__header {
      max-width: 650px;
      width: 100%;
      margin: 0 auto;
      text-align: center;
      @include mq(tablet) {
        text-align: left; } }

    &__blocks {
      display: flex;
      justify-content: center;
      width: 100%;
      margin: auto;
      height: 112px;
      @include mq(tablet) {
        flex-direction: column;
        height: auto; } }

    &__block {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      max-width: 250px;
      width: 100%;
      margin: 0 25px;
      img {
        width: 50px; }
      @include mq(tablet) {
        max-width: 90%;
        margin: 0 auto;
        margin-top: 40px; }


      .icon-button {
        width: 100%;

        &__icon {
          margin-left: auto; }
        @include mq(tablet) {
          margin-top: 16px; } }

      &__mail {
        border-radius: 50%;
        overflow: hidden;
        box-shadow: 0 3px 12px rgba(0, 0, 0, 0.12);
        img {
          width: 100%;
          height: 100%; } } } }

  &__news {
    margin: 40px auto 80px;
    display: flex;
    justify-content: space-between;
    @include mq(mobile) {
      flex-direction: column; } }

  &__new {
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.12);
    padding: 40px 0 30px;
    flex: 0 1 48%;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    @include mq(mobile) {
      margin-top: 20px; }

    &__link {
      justify-content: flex-end;
      display: flex;
      margin-right: 20px; }
    &__title-news {
      text-align: left;
      font-size: rem(25);
      font-style: italic;
      font-family: 'Playfair Display';
      padding: 30px 30px 20px; } }

  &__gallery {
    height: 430px;
    position: relative;
    padding-bottom: 12px;
    margin-bottom: 80px;
    @include mq(mobile) {
      height: 145px; }

    &__container {
      height: 100%;
      border-radius: 10px;
      @include box-shadow; }

    &__slide {
      width: 100%;
      height: 100%;
      // overflow: hidden
      border-radius: 10px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        overflow: hidden;
        border-radius: 10px;
        @include ie11 {
          font-family: 'object-fit: cover'; } } } // Hack Object-fit for IE 11

    &__nav {
      width: 37px;
      height: 37px;
      border: 1px solid $primary-color;
      border-radius: 50%;
      color: $primary-color;
      @include mq(mobile) {
        position: static;
        margin: 0;
        display: inline-flex; }

      &::after, &::before {
        font-size: rem(14); }
      &.swiper-button-next {
        right: -65px; }
      &.swiper-button-prev {
        left: -65px; } }

    &__arrows {
      @include mq(mobile) {
        text-align: center;
        padding-top: 10px; } }

    &__pagination {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      bottom: 0;
      @include mq(mobile) {
        display: none; }

      span {
        border-radius: 0;
        width: 25px;
        height: 2px;
        margin-right: 7px;
        &:last-child {
          margin-right: 0; } }

      .swiper-pagination-bullet-active {
        background: $primary-color; } } } }

.offer ol,
.offer ul {
  list-style-type: disc;
  padding-left: 20px;
  li {
    line-height: 2; }
  li::marker {
    color: $engineering-color; }
  p {
    line-height: 2;
    font-size: rem(20); } }
