.tag {
  position: relative;
  display: inline-flex;
  align-items: center;
  font-size: rem(14);
  text-transform: uppercase;
  letter-spacing: space(10);
  line-height: 1.8;
  font-weight: $extrabold;

  .icon {
    height: 18px;
    width: 18px;
    margin-right: 10px; }

  &--event {
    .inner {
      fill: $light-color; }
    .outer {
      stroke: $light-color; } }

  &--1-data-led-strategy,
  &--study {
    .inner {
      fill: $strategy-color; }
    .outer {
      stroke: $strategy-color; } }

  &--2-data-science-ai {
    .inner {
      fill: $science-color; }
    .outer {
      stroke: $science-color; } }

  &--3-digital-acceleration {
    .inner {
      fill: $digital-color; }
    .outer {
      stroke: $digital-color; } }

  &--data-governance-engineering,
  &--insight {
    .inner {
      fill: $engineering-color; }
    .outer {
      stroke: $engineering-color; } }

  &--digital-performance {
    .inner {
      fill: $performance-color; }
    .outer {
      stroke: $performance-color; } } }
