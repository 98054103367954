.block-speakers {
  padding-top: 45px;
  & > .icon {
    max-width: 25px;
    border: 1px solid red; }


  .scrollbar {
    max-width: 400px; }

  &__slider {
    margin-top: 20px; }

  &__item {
    position: relative;
    display: flex;
    height: auto;
    max-width: 290px;
    width: 100%;
    margin-right: 28px;
    opacity: .35;
    transition: 350ms ease;
    @include box-shadow;

    &.is-active {
      opacity: 1;

      .icon {
        transform: none; } }

    &__image {
      width: 80px;
      height: 100%;
      flex-shrink: 0;
      background-position: center;
      background-size: cover; }

    &__infos {
      padding: 20px; }

    .name {
      text-transform: uppercase;
      font-weight: $extrabold;
      color: $medium-color; }

    .job {
      padding-top: 5px;
      display: block;
      font-size: rem(18);
      font-family: $alternate-font;
      line-height: 1.33; }

    .icon {
      position: absolute;
      top: calc(100% + 10px);
      left: 0;
      transform: rotate(-180deg);
      transition: 350ms $ease-in-out;
      max-width: 25px; } }

  &__quotes {
    margin-top: 50px;
    position: relative;
    @include ie11 {
      margin-top: 70px;
      display: block;
      height: auto;
      min-height: 110px; } }

  &__quote {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    background-color: $light-color;
    opacity: 0;
    transition: 350ms ease;
    @include box-shadow;

    &.is-active {
      opacity: 1; } } }

.news {
  & .icon:not(.icon--logo) {
    max-width: 20px;
    max-height: 20px; } }
