.client-case-card {
  width: 100%;

  &__title {
    font-family: $alternate-font;
    font-size: rem(25);
    letter-spacing: space(10); }

  &__inner {
    margin-top: 15px;
    display: flex;
    flex-direction: column; }

  &__image {
    position: relative;
    width: 100%;
    height: 100%;
    display: block;
    margin-bottom: 10px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain; } }
  &__description {
    font-size: rem(17);
    line-height: 1.3; }

  &__infos {
    padding: 20px 28px;
    background-color: $light-color; }

  .name {
    text-transform: uppercase;
    font-size: rem(16);
    font-weight: $extrabold;
    color: $medium-color; }



  .icon-button {
    margin-top: 60px; } }
