.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  z-index: z(nav);
  @include mq(tablet) {
    &.is-open {
      & .header__logo {
          opacity: 1;
          pointer-events: all; } } }

  &__main {
    position: relative;
    height: 80px;
    z-index: 2;
    @include mq(tablet) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background-color: $primary-color;
      overflow: auto;
      transform: translateX(-100%);

      .is-open & {
        transform: none;
        transition: transform 600ms $ease-in-out; } }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $light-color;
      transform-origin: top center;
      z-index: -1;
      opacity: 0;
      transition: 300ms $ease-in-out;
      @include box-shadow;
      @include mq(tablet) {
        display: none; } }

    .header--dropdown &::before {
      transform: scaleY(1.5); }

    .header--dropdown &::before,
    .is-solid &::before,
    .header--solid &::before {
      opacity: 1; }

    .wrapper {
      display: flex;
      align-items: center;
      height: 100%;
      a {}

      @include mq(tablet) {
        flex-direction: column;
        align-items: flex-start;
        padding-top: 30px; } } }

  &__logo {
    display: block;
    height: 30px;
    width: 152px; // Weird bug
    transform: translateY(8px);
    transition: opacity 400ms ease;
    @include ie11 {
      margin: 35px 0px 20px 0px;
      width: 100%;
      height: 40px; }
    @include mq(tablet) {
      display: none; }

    .letter,
    .shape {
      transition: 300ms ease; }

    .is-solid &,
    .header--solid &,
    .header--dropdown & {
      .letter {
        fill: #77777A;
        @include mq(tablet) {
          fill: $light-color; } }
      .shape {
        fill: $primary-color;
        @include mq(tablet) {
          fill: $light-color; } } }

    &__link {
      height: 100%;
      display: flex;
      align-items: center; } }

  &__items {
    display: flex;
    align-items: center;
    margin-left: auto;
    height: 100%;
    @include mq(tablet) {
      flex-direction: column;
      align-items: flex-start;
      margin-left: 40px;
      padding-top: 100px;
      width: 85%; } }

  &__nav {
    display: flex;
    align-items: center;
    height: 100%;
    @include mq(tablet) {
      flex-direction: column;
      align-items: flex-start;
      height: auto;
      width: 100%; }

    &__item {
      position: relative;
      display: flex;
      align-items: center;
      text-decoration: none;
      color: $dark-color;
      text-transform: uppercase;
      margin-right: 40px;
      font-size: rem(14);
      letter-spacing: space(4);
      height: 100%;
      transition: 300ms $ease-in-out;
      @include mq(tablet) {
        color: $light-color;
        font-size: rem(28);
        margin-top: 20px;
        height: auto;
        margin-right: 0; }

      &::after {
        position: absolute;
        content: '';
        display: block;
        bottom: 25%;
        left: 0;
        height: 3px;
        width: 100%;
        background-color: $primary-color;
        transition: 500ms $ease-in-out;
        transform: scaleX(0);
        @include mq(tablet) {
          display: none; } }

      &--trigger {
        margin-right: 0; }

      .header--light & {
        color: $light-color; }

      .is-solid & {
        color: $dark-color;
        @include mq(tablet) {
          color: $light-color; } }

      &:hover {
        color: $primary-color;
        @include mq(tablet) {
          color: $light-color; }

        &::after {
          transform: none; } }

      &--contact {
        display: flex;
        align-items: center;
        color: $primary-color;
        font-weight: $extrabold;
        @include mq(tablet) {
          color: $light-color; }

        .is-solid & {
          color: $primary-color;
          @include mq(tablet) {
            color: $light-color; } }

        &::before {
          content: '';
          display: block;
          width: 11px;
          height: 11px;
          border-radius: 6px;
          background-color: currentColor;
          margin-right: 25px;
          transition: 300ms ease; }

        &::after {
          display: none; } } } }

  &__dropdown {
    height: 100%;
    margin-right: 40px;
    @include mq(tablet) {
      height: auto;
      margin-right: 0;
      width: 100%;
      display: flex;
      flex-direction: column; }

    &.is-open {
      .header__dropdown__items {
        display: flex;
        transform: none;
        opacity: 1;
        pointer-events: all;
        @include mq(tablet) {
          display: none; } } }
    &.is-visible {
      .header__dropdown__items {
        display: flex;
        opacity: 1;
        pointer-events: all; } }

    &__wrapper {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: flex-start;

      button {
        margin-top: 20px;
        margin-left: auto;
        display: none;
        @include mq(tablet) {
          display: block; } }

      .icon {
        transform: rotate(-90deg);
        transition: transform 200ms ease;
        width: 15px;
        .is-visible & {
          transform: rotate(90deg); }


        svg path {
          stroke-width: 1.5;
          stroke: $light-color; } } }

    &__items {
      position: absolute;
      top: 100%;
      left: 0;
      display: flex;
      justify-content: center;
      width: 100%;
      transition: 300ms ease;
      opacity: 0;
      pointer-events: none;
      @include mq(tablet) {
        display: none;
        opacity: 1;
        width: 100%;
        position: static;
        flex-direction: column; }

      // Bunch of importants but no choice :(
      &--mobile {
        display: none !important;
        @include mq(tablet) {
          .is-visible & {
            display: flex !important; } } }

      &--desktop {
        @include mq(tablet) {
          display: none !important; } } }

    &__item {
      position: relative;
      font-size: rem(17);
      margin: 0 20px;
      padding: 10px 0;
      text-decoration: none;
      color: $dark-color;
      overflow: visible;
      @include mq(tablet) {
        margin: 15px 15px 0;
        padding: 0;
        color: $light-color; }

      .is-solid & {
        color: $dark-color;
        @include mq(tablet) {
          color: $light-color; } }

      &:hover {
        color: $primary-color;
        @include mq(tablet) {
          color: $light-color; } }

      &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: -3px;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: $primary-color;
        transform-origin: bottom center;
        transform: scaleY(0);
        transition: 300ms ease; }

      &.is-active::after {
        transform: none; } } }

  &__mobile {
    position: relative;
    display: none;
    justify-content: space-between;
    align-items: center;
    padding: 16px 16px 0;
    z-index: 4;
    @include mq(tablet) {
      display: flex;

      & > a {
        .is-solid & {
          pointer-events: none; }
        .is-search & {
          pointer-events: none; }
        .is-open & {
          pointer-events: all; } }

      .header__logo {
        display: block;

        .is-solid & {
          opacity: 0; }
        .is-search & {
          opacity: 0; }
        .is-open & {
          opacity: 1; } } } }

  &__trigger {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    background-color: $light-color;
    @include box-shadow;

    .icon {
      width: 20px; }

    .icon--close {
      display: none;
      .is-open & {
        display: block; } }
    .icon--burger {
      display: block;
      .is-open & {
        display: none; } } }

  &__breadcrumbs {
    position: relative;
    height: 30px;
    background-color: transparentize($light-color, .05);
    color: $dark-color;
    font-size: rem(12);
    letter-spacing: space(12);
    z-index: 1;
    @include box-shadow(14px);
    @include mq(tablet) {
      display: none; }

    &__inner {
      display: flex;
      align-items: center;
      height: 100%; }

    .separator {
      padding: 0 5px; }

    a {
      text-decoration: none;
      color: inherit;

      &:hover {
        text-decoration: underline; } }

    &__item {

      &--current {
        color: $primary-color; } } } }
