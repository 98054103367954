.expert-card {
  max-width: 400px;
  width: 100%;

  &__title {
    font-family: $alternate-font;
    font-size: rem(25);
    letter-spacing: space(10); }

  &__inner {
    margin-top: 15px;
    display: flex;
    min-height: 265px;
    @include box-shadow(12px); }

  &__image {
    position: relative;
    max-width: 185px;
    width: 100%;
    flex-shrink: 0;
    overflow: hidden;
    @include mq(tablet) {
      flex-shrink: 1; }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      height: 100%; } }

  &__infos {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 20px 28px;
    background-color: $light-color; }

  .name {
    text-transform: uppercase;
    font-size: rem(16);
    font-weight: $extrabold;
    color: $medium-color; }

  .function {
    padding-top: 15px;
    font-family: $alternate-font;
    font-size: rem(25);
    letter-spacing: space(10);
    line-height: 1.1; }

  .icon-button {
    margin-top: auto; } }
