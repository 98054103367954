html {
  height: 100%; }

html, body {
  margin: 0;
  padding: 0;
  text-size-adjust: 100%; } // Prevent re-scaling text in portrait mode https://developer.mozilla.org/en-US/docs/Web/CSS/text-size-adjust

*, *::before, *::after {
  box-sizing: border-box;
  scroll-behavior: smooth; }

body {
  font-family: $body-font;
  color: $dark-color;
  font-weight: $light;
  font-size: rem(17);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility !important; }

.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &.has-waves {
    background-image: url('/img/WavesHeader.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    overflow: hidden; }

  &__content {
    flex: 1;
    @include ie11 {
      flex: none; } }

  &__header {
    padding-top: 90px;
    @include mq(mobile) {
      padding-top: 100px; }

    &--nested {
      padding-top: 200px;
      @include mq(mobile) {
        padding-top: 100px; } }

    &--centered {
      max-width: 720px;
      width: 100%;
      margin: 0 auto;
      text-align: center; }

    &__intro {
      padding-top: 10px;
      line-height: $default-line-height; } } }

.icon {
  display: inline-block;
  @include ie11 {
    max-width: 26px;
    height: 70px; }
  svg {
    width: 100%;
    height: 100%; } }

.wrapper {
  max-width: $wrapper-width;
  margin: 0 auto;
  width: calc(100% - 32px);
  @include mq(tablet) {
    width: 100%;
    max-width: calc(100% - 32px); }

  &--large {
    max-width: $wrapper-width-large;
    width: calc(100% - 32px); } }

.half-wrapper {
  max-width: calc(#{$wrapper-width} / 2);
  width: 50%;
  @include mq(tablet) {
    width: 100%;
    max-width: calc(100% - 30px);
    margin: 0px auto; }

  &--left {
    margin-left: calc(50vw - #{$wrapper-width / 2} - 8px); // Why 8px ? It aligns correctly at every screen resolution but don't know where it comes from
    @include mq(tablet) {
      margin: 0px auto; } } }

.wysiwyg {
  line-height: $default-line-height;

  p {
    padding-top: 1rem; }

  strong {
    font-weight: $extrabold; }

  h2 {
    font-family: $alternate-font;
    font-size: rem(25);
    margin-top: 40px; }

  a {
    color: $primary-color; }

  li {
    padding-left: .75em;
    display: block;
    padding-top: 10px;
    text-indent: -.75em;

    &::before {
      content: '';
      display: inline-block;
      width: 6px;
      height: 6px;
      max-height: 6px;
      border-radius: 4px;
      background-color: $primary-color;
      vertical-align: middle;
      margin-right: 6px; } }

  &_colorize {
    color: $primary-color;
    display: contents; } }

button {
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background: transparent;
  font: inherit;
  text-align: left;
  color: inherit;
  cursor: pointer;
  outline: none; }

[data-animation] {
  pointer-events: none; }

.banners {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  bottom: 0;
  width: 100%;
  padding: 22px 50px;
  pointer-events: none;
  z-index: 1;
  @include mq(tablet) {
    flex-direction: column;
    align-items: center;
    padding: 22px 4%; } }
