.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  z-index: z(overlay);

  &.is-open {
    display: block; }

  &__overlay {
    height: 100%;
    padding: 10vh 20px;
    background-color: transparentize($dark-color, .1);
    overflow: auto; }

  &__container {
    display: flex;
    flex-direction: column;
    max-width: 890px;
    width: 100%;
    margin: 0 auto;
    background-color: $light-color;
    border-radius: 10px;
    overflow: hidden; }

  &[aria-hidden="false"] .modal__overlay {
    animation: overlay-fade-in 250ms ease; }

  &[aria-hidden="false"] .modal__container {
    animation: modal-scale-up 500ms $ease-in-out; }

  &[aria-hidden="true"] .modal__overlay {
    animation: overlay-fade-out 350ms ease; } }

.modal-video {

  &__iframe {
    position: relative;
    padding-top: 56.25%;
    background-color: $dark-color; }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; } }

.modal-success {
  padding: 50px;
  text-align: center;

  &__desc {
    padding-top: 10px;
    line-height: $default-line-height; }

  .icon-button {
    margin-top: 65px; } }

@keyframes overlay-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes overlay-fade-out {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes modal-scale-up {
  from {
    transform: scale(.9) translateY(10%);
    opacity: 0; }
  to {
    opacity: 1;
    transform: none; } }
