.error404 {
  padding: 200px 0px 40px 0px;
  @include mq(mobile) {
    padding: 100px 0px 40px 0px; }
  &__inner {
    max-width: calc(1252px - 30px);
    margin: 0px auto;
    display: flex;
    justify-content: center; }

  &__title {
    display: block;
    font-size: rem(120);
    letter-spacing: space(-10);
    font-weight: $extrabold;
    @include mq(mobile) {
      font-size: rem(97);
      text-align: center; }
    .outline {
      position: relative;
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: $dark-color;
      @include mq(mobile) {
        display: block; }
      &::before {
        content: attr(data-text);
        position: absolute;
        // top: -2px
        // left: -2px
        transform: translate3d(4px, 4px, 1px);
        @include mq(mobile) {
          display: none; } } } } }
.error404-cta {
  .floating-cta {
    margin-top: 0px; }

  .section-title--line {
    &::before {
      display: none; } }

  .icon-button {
    padding-left: 4px;
    padding-right: 20px;

    &__icon {
      margin-left: 0;
      margin-right: 15px; } } }
