.form {

  .hidden {
    display: none; }

  &__required {
    font-size: rem(15);
    span {
      font-weight: $extrabold;
      color: $primary-color; } }

  &__errors {
    li {
      padding-top: 10px;
      color: $primary-color;
      font-size: rem(14); } }

  &__submit {
    margin-top: 10px;

    .is-loading & {
      pointer-events: none; } } }

.field {
  max-width: 100%;
  width: 100%;
  padding-top: 60px;
  transition: opacity 200ms ease;
  @include mq(tablet) {
    padding-top: 40px; }

  .is-loading & {
    opacity: .5;
    pointer-events: none; }

  &__label {
    display: block;
    font-weight: $extrabold;
    font-size: rem(16);
    text-transform: uppercase;

    &.required {
      &::after {
        content: ' *';
        color: $primary-color; } }

    .field--checkbox & {
      padding-left: 8px;
      text-transform: initial;
      font-weight: $light;
      font-size: rem(12);

      &::after {
        display: none; } } }

  &__input {
    height: 35px;
    width: 100%;
    border: 0;
    padding: 12px 0 6px;
    font: inherit;
    font-size: rem(16);
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0;

    @include placeholder(#989898);

    &:invalid {
      box-shadow: none; }

    &::-ms-clear {
      display: none; }

    &:focus {
      border-color: $dark-color;
      outline: none; }

    .field--text & {
      border-bottom: 1px solid $medium-color-dark; }

    .field--textarea & {
      margin-top: 5px;
      min-height: 140px;
      padding: 12px 6px;
      border: 1px solid $medium-color-dark;
      border-radius: 5px;
      resize: none; }

    &__select {
      padding-bottom: 0; } }

  &--checkbox {
    input {
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      margin: 0;
      width: 12px;
      height: 12px;
      border: 1px solid $dark-color;

      &:invalid {
        box-shadow: none; }

      &:checked {
        border-color: $primary-color;
        background-color: $primary-color;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e"); } }

    label {
      display: flex;
      align-items: center; }

    a {
      color: inherit; } }

  &__errors {
    li {
      padding-top: 10px;
      list-style: none;
      font-size: rem(12);
      color: $primary-color; } } }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active, {
  -webkit-box-shadow: 0 0 0 30px white inset !important; }

select, select:hover {
  background-color: #FFFFFF !important; }

option {
  background-color: white !important; }
