/**
  * Upload font files in the fonts directory and load them here
  * For each font style or weight you need to create a new font-face loading both the woff and woff2 formats
  */

@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy-Light.woff2') format("woff2"), url('/fonts/Gilroy-Light.woff') format("woff");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy-ExtraBold.woff2') format("woff2"), url('/fonts/Gilroy-ExtraBold.woff') format("woff");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Playfair Display';
  src: url('/fonts/PlayfairDisplay-Regular.woff2') format("woff2"), url('/fonts/PlayfairDisplay-Regular.woff') format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Playfair Display';
  src: url('/fonts/PlayfairDisplay-Italic.woff2') format("woff2"), url('/fonts/PlayfairDisplay-Italic.woff') format("woff");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'Playfair Display';
  src: url('/fonts/PlayfairDisplay-Black.woff2') format("woff2"), url('/fonts/PlayfairDisplay-Black.woff') format("woff");
  font-weight: 900;
  font-style: normal; }
