.footer {
  position: relative;

  &__animation {
    position: absolute;
    bottom: 99%;
    right: 0;
    width: 50%;
    @include mq(mobile) {
      width: 100%; } }

  &__inner {
    position: relative;
    padding: 65px 0;
    @include mq(mobile) {
      padding: 40px 0px; }
    & .wrapper {
      position: relative; }

    .footer--minimal & {
      padding-top: 0;
      padding-bottom: 20px; } }

  .hero-title {
    span {
      font-size: rem(105);
      @include mq(tablet) {
        font-size: rem(43);
        font-size: 12vw !important; } } }

  &__title {
    &__animation {
      display: block;
      max-width: 800px; } }

  &__locations {
    display: flex;
    padding-top: 40px;
    @include mq(tablet) {
      position: relative; }
    @include mq(mobile) {
      justify-content: space-between; }

    .link {
      margin-right: 50px;
      @include mq(tablet) {
        &.address-is-visible ~ .footer__location__address {
          opacity: 1; } }
      @include mq(mobile) {
        margin: 0px; } } }

  &__location {
    position: relative;
    @include mq(tablet) {
      position: static; }

    &:hover {
      .footer__location__address {
        opacity: 1; } }

    &__address {
      position: absolute;
      top: 150%;
      left: 50px;
      width: 200%;
      opacity: 0;
      pointer-events: none;
      transition: opacity 500ms $ease-in-out;
      @include mq(tablet) {
        top: 120%;
        left: 0;
        width: 100%;
        text-align: center;
        background-color: $light-color;
        padding: 10px;
        border-radius: 4px;
        box-shadow: $default-box-shadow;
        font-size: rem(14);
        line-height: 1.3; } } }

  &__infos {
    display: flex;
    justify-content: space-between;
    // padding: 20px 0 10px
    padding: 45px 0 10px;
    border-bottom: 1px solid $dark-color;
    font-size: rem(14);
    align-items: center;
    @include mq(mobile) {
      flex-direction: column;
      padding-top: 100px;
      align-items: flex-start; }

    .footer--minimal & {
      padding-top: 70px; }

    a {
      text-decoration: none;
      color: inherit;
      text-transform: uppercase; }

    .copyright {
      margin-right: 20px; } }

  &__links {
    @include mq(mobile) {
      display: flex;
      flex-wrap: wrap;
      order: 2;
      justify-content: space-between;
      padding-bottom: 10px;

      span {
        width: 100%; } }

    a {
      margin-right: 20px;
      @include mq(mobile) {
        margin-right: 0;
        margin-top: 10px; } } }

  &__social {
    display: flex;
    @include mq(mobile) {
      order: 1;
      margin-bottom: 15px; }

    &__links {
      margin-left: 10px;
      @include mq(mobile) {
        display: flex;
        justify-content: space-between;
        margin-left: 15px; }

      a {
        margin: 0 10px;

        &:hover {
          path {
            fill: $primary-color; } } }

      .icon {
        height: 12px;
        width: 12px; } } }

  &__maps {

    &__item {
      position: absolute;
      top: 0;
      right: 0;
      z-index: -1;
      height: 100%;
      opacity: 0;
      visibility: hidden;
      transition: 400ms $ease-in-out;

      &.is-visible {
        opacity: 1;
        visibility: visible; } } }

  #scroll-to-top {
    position: absolute;
    top: 30px;
    right: -60px;
    cursor: pointer;
    @include mq(tablet) {
      top: 0px;
      right: 0px; }
    &.minimal {
      top: 40px; } }

  &__digitalGroup {
    padding-top: 80px;
    display: flex;
    justify-content: flex-start;
    a {
      display: block;
      &:hover {
        pointer-events: all; } }
    img {
      height: 60px; } }

  &__widget {
    min-width: 210px;
    @include mq(tablet) {
      margin-bottom: 20px; } } }
