.contact-form {
  margin: 90px auto 60px;
  padding: 55px 0 100px;
  border-radius: 10px;
  background-color: $light-color;
  position: relative;
  @include box-shadow;
  @include mq(tablet) {
    padding: 30px 0;

    .form__required {
      padding-top: 5px;
      padding-left: 60px; } }

  &__inner {
    display: flex;
    align-items: flex-start;
    @include mq(tablet) {
      flex-direction: column; } }

  &__fields {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .field {
      max-width: 50%;
      padding-right: 28px;
      @include mq(tablet) {
        max-width: 100%; } } }

  &__message {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    max-width: 400px;
    width: 100%; }

  &__cgu label {
    justify-content: flex-end;
    @include mq(tablet) {
      margin-bottom: 10px; } } }
