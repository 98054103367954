.expertise {
  &__entry {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 100px 0px;
    flex-wrap: wrap;
    @include mq(mobile) {
      width: calc(100% - 30px);
      margin: 0px auto;
      padding: 40px 0px; }
    &__column {
      flex: 0 0 48%;
      @include mq(mobile) {
        flex: 1 1 100%;
        display: flex;
        justify-content: center; }
      p {
        line-height: 1.5;
        @include mq(mobile) {
          text-align: center; } } } }
  &__illu {
    &__img {
      width: 100%;
      object-fit: contain;
      @include mq(mobile) {
        width: calc(100% - 40px);
        margin: 0px 20px 40px 20px; } } }

  &__offer {
    &__wrapper {
      margin: 0px auto; }
    &__description {
      max-width: 66%;
      width: 100%;
      line-height: 1.5;
      margin: 90px 0px 40px 0px;
      @include mq(tablet) {
        max-width: 100%;
        margin: 20px 0px; } } }
  &__offerbox {
    width: calc(20% - 21px);
    max-width: 230px;
    height: 180px;
    border-radius: 4px;
    border-left: 4px solid red;
    padding: 20px;
    display: flex;
    align-items: center;
    color: $dark-color;
    font-family: $alternate-font;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, .16);
    font-size: rem(22);
    @include mq(tablet) {
      width: 100%;
      max-width: inherit;
      height: 70px;
      margin-bottom: 15px; }
    &:hover {
      cursor: pointer; }

    &--data-led-strategy {
      border-left: 4px solid $strategy-color;
      &:hover {
        background: rgba($strategy-color, 0.3); }
      &.is-active {
        background: $strategy-color;
        color: $light-color; } }

    &--data-science-ai {
      border-left: 4px solid $science-color;
      &:hover {
        background: rgba($science-color, 0.3); }
      &.is-active {
        background: $science-color;
        color: $light-color; } }

    &--digital-performance {
      border-left: 4px solid $performance-color;
      &:hover {
        background: rgba($performance-color, 0.3); }
      &.is-active {
        background: $performance-color;
        color: $light-color; } }

    &--digital-acceleration {
      border-left: 4px solid $digital-color;
      &:hover {
        background: rgba($digital-color, 0.3); }
      &.is-active {
        background: $digital-color;
        color: $light-color; } }

    &--data-governance-engineering {
      border-left: 4px solid $engineering-color;
      &:hover {
        background: rgba($engineering-color, 0.3); }
      &.is-active {
        background: $engineering-color;
        color: $light-color; } }
    &__wrapper {
      display: flex;
      justify-content: space-between;
      @include mq(tablet) {
        flex-direction: column; } } } }

.trade {
  &-offer {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 12px 20px;
    padding-right: 80px;
    max-width: calc(33% - 14px);
    width: 100%;
    height: 116px;
    margin-top: 28px;
    border-radius: 4px;
    background-color: #fff;
    @include box-shadow;
    @include mq(mobile) {
        max-width: 100%;
        margin: 20px 0px 10px 0px; }

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 33px;
      width: 20px;
      height: 20px;
      border-radius: 13px;
      transform: translate3d(0, -50%, 0);
      transition: 350ms $ease-in-out;
      background-color: $primary-color; }

    &::after {
      width: 26px;
      height: 26px;
      right: 30px;
      border: 1px solid transparent;
      background-color: transparent;
      transition-delay: 75ms; }

    &:hover {
      cursor: pointer;
      &::before {
        transform: translate3d(0, -50%, 0) scale(1.2); }
      &::after {
        transform: translate3d(0, -50%, 0) scale(1.3); } }

    &--data-led-strategy {
      &::before {
        background-color: $strategy-color; }
      &::after {
        border-color: $strategy-color; } }

    &--data-science-ai {
      &::before {
        background-color: $science-color; }
      &::after {
        border-color: $science-color; } }

    &--digital-acceleration {
      &::before {
        background-color: $digital-color; }
      &::after {
        border-color: $digital-color; } }

    &--data-governance-engineering {
      &::before {
        background-color: $engineering-color; }
      &::after {
        border-color: $engineering-color; } }

    &--digital-performance {
      &::before {
        background-color: $performance-color; }
      &::after {
        border-color: $performance-color; } }

    &-hub__main {
      position: relative;
      padding: 0px 0px 80px 0px;
      transition: opacity 300ms ease;

      &.is-fetching {
        opacity: .4; } }

    &__total {
      line-height: $default-line-height;
      border-bottom: 1px solid $dark-color; }

    &-hub__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      position: relative;

      &::after {
        content: '';
        max-width: calc(33% - 14px);
        width: 100%; } }

    &__more {
      margin: 48px auto 0;

      &.is-fetching {
        opacity: .4; } }

    &__link {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 2; }

    &__title {
      font-family: $body-font;
      font-size: rem(17); } }

  &__total {
    line-height: $default-line-height;
    border-bottom: 1px solid $dark-color; }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &::after {
      content: '';
      max-width: calc(33% - 14px);
      width: 100%; } } }
