.block-article {
  padding-top: 45px;

  img {
    width: 100%;
    @include mq(tablet) {
      max-width: 100%;
      object-fit: contain;
      height: auto !important; } }

  h1, h2, h3, h4, h5, h6 {
    padding-top: 15px;
    font-family: $alternate-font;
    font-size: rem(25);
    letter-spacing: space(10);
    color: $primary-color;
    line-height: 1.1; } }
