.loader {
  position: relative;
  display: flex;
  align-items: center;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: $primary-color;

  &::before,
  &::after {
    position: absolute;
    top: 0;
    content: '';
    display: block;
    height: 8px;
    width: 8px;
    flex-shrink: 0;
    border-radius: 4px; }

  &::before {
    left: -130%;
 }    //background-color: $perf-color

  &::after {
    left: 130%;
 } }    //background-color: $data-color

@keyframe fade {
  0%, 100% {
    opacity: 1; }
  60% {
    opacity: 0; } }
