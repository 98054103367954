.contact {

  &__inner {
    padding-top: 50px; }

  &__offices {
    position: relative;
    padding: 220px 0 80px;
    margin-top: -60px;
    @include mq(tablet) {
      padding: 120px 0 80px; }

    &__inner {
      display: flex;
      @include mq(tablet) {
        flex-direction: column;
        align-items: center; } }

    &__item {
      max-width: 30%;
      flex: 1;
      @include mq(tablet) {
        padding-top: 40px;
        max-width: 180px; }

      address {
        padding-top: 30px;
        line-height: $default-line-height; }

      .telephone {
        display: block;
        padding-top: 1rem; } }

    &__map {
      position: absolute;
      top: 0;
      right: 0;
      z-index: -1;
      height: 100%;
      opacity: 0;
      visibility: hidden;
      transition: 400ms $ease-in-out;
      transform: translateX(20%);

      &.is-visible {
        opacity: 1;
        visibility: visible; } } } }
