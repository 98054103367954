.icon-button {
  display: inline-flex;
  align-items: center;
  padding: 4px;
  padding-left: 20px;
  background-color: $primary-color;
  line-height: 1;
  text-decoration: none;
  color: $light-color;
  border-radius: 20px;
  transition: background-color 200ms ease;

  &:active,
  .is-loading & {
    background-color: #AD004A;

    .icon-button__icon {
      background-color: #790034; } }

  &--linkedin {
    background-color: #006393;

    .icon-button__icon {
      background-color: #0E76A8; } }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    margin-left: 15px;
    background-color: #AD004A;
    border-radius: 15px;
    transition: background-color 200ms ease;

    .icon--linkedin {
      width: 12px;
      path {
        fill: $light-color; } }

    .icon--chevron-right,
    .icon--chevron-left {
      width: 6px;
      path {
        stroke: $light-color;
        stroke-width: 2; } }

    .icon--check {
      width: 12px;
      height: 14px; } } }

.more-button {
  position: relative;
  display: flex;
  align-items: center;
  font-size: rem(16);
  font-weight: $extrabold;

  &:hover {
    &::before,
    &::after {
      transform: none; } }

  &:active {
    color: transparentize($dark-color, .3); }

  &::before,
  &::after {
    content: '';
    display: block; }

  &::before {
    width: 9px;
    height: 9px;
    margin-right: 8px;
    background-image: url('/img/plus.svg');
    background-size: contain;
    background-repeat: no-repeat;
    transform: rotate(-90deg);
    transition: transform 400ms $ease-in-out; }

  &::after {
    height: 2px;
    position: absolute;
    bottom: -6px;
    right: 0;
    width: calc(100% - 17px);
    background-color: $primary-color;
    transform: scaleX(0);
    transition: transform 400ms $ease-in-out; } }
