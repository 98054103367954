.link {
  display: inline-flex;
  position: relative;
  text-decoration: none;
  font-weight: $extrabold;

  &::before,
  &::after {
    display: block;
    content: '';
    position: absolute;
    top: 50%;
    left: 4px;
    width: 8px;
    height: 8px;
    border-radius: 80px;
    transform: translate3d(-50%, -50%, 0);
    transition: 500ms $ease-in-out;
    pointer-events: none;
    will-change: width, height; }

  &::before {
    background-color: $primary-color; }
  &::after {
    border: 1px solid $primary-color;
    transition-delay: 50ms; }

  &:hover {
    &::before {
      height: 72px;
      width: 72px; }
    &::after {
      height: 96px;
      width: 96px; }
    .light {
      clip-path: circle(36px at 4px 50%); } }

  .light,
  .dark {
    display: flex;
    align-items: center;
    &::before {
      display: block;
      content: '';
      width: 8px;
      height: 8px;
      border-radius: 40px;
      margin-right: 10px; } }

  .dark {
    color: $dark-color;
    &::before {
      background-color: $dark-color;
      @include ie11 {
        background-color: $primary-color; } } }

  .light {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    color: $light-color;
    clip-path: circle(0 at 4px 50%);
    transition: 400ms $ease-in-out;
    will-change: clip-path;
    @include ie11 {
      color: $dark-color; }

    &::before {
      background-color: $light-color;
      @include ie11 {
        background-color: $primary-color; } }
    &:hover {
      &::before {
        @include ie11 {
          background-color: $light-color; } } } } }


