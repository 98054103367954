[data-cookies-disclaimer] {
   max-width: 455px;
   width: 100%;
   @include mq(tablet) {
    max-width: 100%; } }

.cookies-disclaimer {
  pointer-events: all;
  width: 100%;
  background-color: $light-color;
  border-radius: 10px;
  padding: 22px;
  @include box-shadow;
  z-index: z(overlay);
  transition: opacity .6s ease;
  @include mq(mobile) {
    display: flex;
    align-items: flex-start; }

  &.hidden {
    opacity: 0;
    display: none; }

  &__label {
    font-size: rem(16);
    font-weight: 800;
    a {
      color: $primary-color; } }

  &__cta {
    text-align: right;
    margin-top: -10px;
    // display: flex
    margin-left: 14px; }

  &__close {
    display: flex;
    margin-left: auto;
    @include mq(mobile) {
      flex-shrink: 0;
      // max-width: 115px
      .icon-button__icon {
        flex-shrink: 0; } } } }

.cookies-disclaimer-details {
  width: 455px;
  position: fixed;
  bottom: 180px;
  right: 90px;
  background-color: $light-color;
  border-radius: 10px;
  padding: 22px;
  @include box-shadow;
  z-index: z(overlay);
  transition: opacity .6s ease;
  @include mq(mobile) {
    width: 90vw;
    right: 50%;
    bottom: 150px;
    transform: translateX(50%); }

  &.hidden {
    opacity: 0;
    display: none; }

  &__title {
    font-family: $alternate-font;
    font-size: rem(25);
    margin-bottom: 15px;
    position: relative;
    padding-left: 50px;
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: .6em;
      left: -22px;
      width: 60px;
      height: 1px;
      background-color: $dark-color;
      margin-right: 20px; } }

  &__content {
    font-size: rem(17);
    line-height: 20px; } }
