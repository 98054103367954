.info-banner {
  pointer-events: all;
  position: relative;
  max-width: 510px;
  width: 100%;
  background-color: $light-color;
  padding: 55px 22px 22px;
  border-radius: 10px;
  z-index: z(overlay);
  transition: max-height .6s linear;
  max-height: 500px;
  margin-left: auto;
  @include box-shadow;
  @include mq(tablet) {
    max-width: 100%;
    margin-top: 22px; }

  &.hidden {
    display: none; }

  &__title {
    font-family: $alternate-font;
    font-size: rem(25);
    margin-bottom: 15px; }

  &__desc {
    padding-bottom: 12px; }

  &__cta {
    text-align: right;
    margin-top: -10px;
    // display: flex
    margin-bottom: 22px; }

  &__close {
    height: 33px;
    width: 33px;
    border-radius: 50%;
    background-color: $dark-color;
    position: absolute;
    cursor: pointer;
    top: 10px;
    right: 10px;
    @include box-shadow;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s ease;

    &:hover {
      transform: scale(1.1); }

    & > span {
      width: 15px;
      height: 15px;
      display: block;
      position: relative;
      &::before, &::after {
        content: '';
        width: 2px;
        height: 16px;
        background-color: $light-color;
        position: absolute;
        left: 50%;
        top: 50%; }
      &::before {
        transform: translate(-50%, -50%) rotate(45deg); }
      &::after {
        transform: translate(-50%, -50%) rotate(-45deg); } } } }
