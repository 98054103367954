.news-hub {

  &::after {
    display: none;
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: transparentize($dark-color, .1);
    opacity: 0;
    pointer-events: none;
    transition: opacity 400ms ease;
    @include mq(tablet) {
      display: block; } }

  &.is-search::after {
    opacity: 1;
    pointer-events: all; }

  .filters {
    display: none;
    @include mq(tablet) {
      display: block; } }

  .header__dropdown__items {
    left: 50%;
    max-width: $wrapper-width-large;
    width: calc(100% - 32px);
    transform: translateX(-50%) !important;
    @include mq(tablet) {
      transform: none !important; } }

  &__search {
    position: absolute;
    right: 0;
    top: 0;
    @include mq(tablet) {
      display: none; }

    &--mobile {
      display: none;
      position: fixed;
      top: 16px;
      right: 92px;
      z-index: 6;
      @include mq(tablet) {
        display: block; } }

    input {
      height: 35px;
      border: 0;
      padding: 10px;
      font: inherit;
      width: 145px;
      padding-right: 40px;
      transition: 400ms ease;
      @include mq(tablet) {
        width: 60px;
        height: 60px;
        border-radius: 30px; }

      &:invalid {
        border: none;
        box-shadow: none;
        @include mq(tablet) {
          @include box-shadow; } }

      &:-ms-input-placeholder {
        color: $medium-color;
        opacity: 0;
        transition: 400ms ease; }
      &::-webkit-input-placeholder {
        color: $medium-color;
        opacity: 0;
        transition: 400ms ease; }
      &::placeholder {
        color: $medium-color;
        opacity: 0;
        transition: 400ms ease; }

      &:focus,
      &:hover,
      &:valid {
        width: 280px;
        outline: none;
        @include box-shadow;
        @include mq(tablet) {
          width: 210px;

          & ~ .icon {
            right: 16px;
            transform: translate3d(0, -50%, 0); } }

        &:-ms-input-placeholder {
          opacity: 1; }
        &::-webkit-input-placeholder {
          opacity: 1; }
        &::placeholder {
          opacity: 1; } } }

    .icon {
      position: absolute;
      top: 50%;
      right: 10px;
      width: 20px;
      transform: translateY(-50%);
      pointer-events: none;
      @include mq(tablet) {
        right: 50%;
        transform: translate3d(40%, -50%, 0);
        transition: 400ms ease; } } }

  &__inner {
    margin-top: 170px;
    margin-bottom: 120px;
    transition: opacity 300ms ease;

    &.is-fetching {
      opacity: .4; } }

  &__empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 50px;
    min-height: 400px;
    text-align: center;

    strong {
      display: block;
      margin-bottom: 10px;
      font-family: $alternate-font;
      font-style: italic;
      font-size: rem(44); } }

  &__more {
    margin: 50px auto 30px; } }

.news-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @include mq(mobile) {
    padding-bottom: 100px; }


  &::after {
    content: '';
    max-width: calc(33% - 18px);
    width: 100%; }

  &__item {
    position: relative;
    width: 100%;
    max-width: calc(33% - 18px);
    margin-top: 40px;
    height: 460px;
    border-radius: 10px;
    overflow: hidden;
    transform: translateY(25px);
    opacity: 0;
    animation: cancel-transform 0ms $ease-in-out forwards;
    @include box-shadow;
    @include mq(tablet) {
      max-width: 100%; }

    &.is-new {
      animation: cancel-transform 450ms $ease-in-out forwards; }

    &--featured {
      max-width: 100%;
      margin-top: 0;

      .news-grid & {
        max-width: calc(33% - 18.66px);
        margin-top: 40px;
        @include mq(tablet) {
          max-width: 100%; } } }

    a {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2; }

    &__title {
      font-family: $alternate-font;
      font-size: rem(24);
      padding-top: 16px;

      .news-grid__event & {
        font-style: italic; }

      .news-grid__item--featured & {
        font-size: rem(28); } }

    &__excerpt {
      padding-top: 20px;
      font-size: rem(15);
      line-height: 1.2;
      margin-bottom: auto;

      .news-grid__item--featured & {
        padding-top: 10px; } }

    &__author {
      color: $medium-color;
      font-weight: $extrabold;
      font-size: rem(12);
      margin-top: auto;
      text-transform: uppercase;
      @include mq(mobile) {
        font-size: rem(11); } } }

  &__article {

    &__image {
      position: relative;
      height: 220px;
      overflow: hidden;

      img {
        position: absolute;
        top: 0;
        left: 50%;
        width: 100%;
        transform: translate3d(-50%, 0, 0); } }

    &__main {
      padding: 15px 20px 0px 20px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      height: auto;
      height: 225px;
      overflow-y: hidden; }

    &__meta {
      display: flex;
      justify-content: space-between;
      align-items: center; }

    .date {
      text-transform: uppercase;
      font-weight: $extrabold;
      color: $medium-color;
      font-size: rem(12);
      letter-spacing: space(10); } }

  &__event {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 25px 30px;
    background-position: center;
    background-size: cover;
    color: $light-color;

    &::before {
      z-index: 0;
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(to top, rgba(#000, .5), transparent); }

    &__main {
      height: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      @include mq(tablet) {
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start; } }

    &__infos {
      max-width: 680px;
      position: relative; }

    .dark {
      color: inherit; }

    &__date {
      margin: 0 auto;
      text-align: center;
      font-family: $alternate-font;
      letter-spacing: space(10);
      font-size: rem(20);
      font-style: italic;
      text-transform: lowercase;

      .news-grid__item--featured & {
        font-size: rem(28); }

      .day {
        display: block;
        font-size: italic;
        font-weight: $black;
        font-size: rem(40);
        font-style: normal;
        line-height: .2;
        margin-bottom: 20px;

        .news-grid__item--featured & {
          font-size: rem(52); } } } } }

@keyframes cancel-transform {
  to {
    opacity: 1;
    transform: none; } }
