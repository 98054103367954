.card {
  & > a {
    color: inherit;
    text-decoration: none; }

  &:hover {
    .card__image img {
      filter: blur(3px); } }

  &__image {
    background-color: #ededed; }

  &__title {
    padding-top: 15px;
    line-height: $default-line-height;

    strong {
      font-weight: $extrabold; } } }
