.join-us {
  .page__header {
    position: relative;
    padding-bottom: 30px;
    overflow: hidden;

    .wrapper {
      display: flex;
      flex-direction: column;
      position: relative; } }

  &__animation {
    position: absolute;
    bottom: -15%;
    left: -15%;
    height: 95%;
    width: 65%;
    @include mq(tablet) {
      display: none; } }

  &__intro {
    align-self: flex-end;
    padding-top: 30px;
    padding-left: 28px;
    max-width: 50%;
    @include mq(mobile) {
      max-width: 100%;
      padding: 10px 0px 20px 0px; }

    .link {
      margin-top: 55px; } }

  &__culture {
    display: flex;
    align-self: flex-end;
    padding-top: 160px;
    @include mq(mobile) {
      flex-direction: column;
      padding-top: 40px; }

    &__column {
      max-width: 400px;
      width: 100%;
      margin-right: 28px;

      &:last-child {
        margin-right: 0; } }

    &__title {
      font-family: $alternate-font;
      font-size: rem(28);
      font-style: italic;
      margin-bottom: 28px; }

    &__item {
      padding: 40px;
      padding-left: 80px;
      margin-bottom: 28px;
      border-radius: 10px;
      background-color: $light-color;
      @include box-shadow;

      .title {
        position: relative;
        text-transform: uppercase;
        font-weight: $extrabold;
        font-size: rem(16);
        line-height: 1.55;
        color: $medium-color;

        &::before {
          content: '';
          display: block;
          position: absolute;
          top: .6em;
          right: 100%;
          width: 60px;
          height: 2px;
          background-color: $medium-color;
          margin-right: 20px; } }

      .desc {
        padding-top: 30px;
        letter-spacing: space(10);
        line-height: $default-line-height;
        a {
          text-decoration: none;
          color: $primary-color;
          &:hover {
            text-decoration: underline; } } } } }


  &__stats {
    padding-top: 60px;
    padding-bottom: 30%;
    background-color: $medium-color-light;
    background-image: url('/img/paris.png');
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: contain;

    &__inner {
      display: flex;
      flex-wrap: wrap;
      @include mq(tablet) {
        flex-direction: column; } }

    &__column {
      flex: 1;
      max-width: 50%;
      width: 100%;
      @include mq(tablet) {
        max-width: 100%; }

      &:last-child {
        margin-left: 28px;
        @include mq(tablet) {
          margin: 0px 0px 50px 0px; } } }

    &__floating {
      margin-top: 50px;
      margin-left: -50px;
      @include mq(tablet) {
        margin: 50px 0px 20px 0px; } }

    .floating-number {
      margin-bottom: 50px; }

    .card-number {
      display: block;
      margin-bottom: 12px;

      &:nth-of-type(1) {
        max-width: 37.5%; }
      &:nth-of-type(3) {
        max-width: 62.5%; }
      &:nth-of-type(4) {
        max-width: 50%; } } }

  &__videos {
    padding-top: 95px;
    @include mq(tablet) {
      padding: 95px 0px 50px 0px; }

    &__grid {
      display: flex;
      justify-content: space-between;
      padding-top: 50px;
      flex-wrap: wrap;
      @include mq(tablet) {
        flex-direction: column;
        padding-top: 25px; } }

    &__column {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;
      flex: 1;
      flex-wrap: wrap;
      @include mq(tablet) {
        flex-direction: row;
        align-items: flex-start; }

      .video-thumb {
        @include mq(tablet) {
          width: calc(50% - 7px);
          margin-top: 14px;
          position: relative; } } }

    &__notice {
      padding-top: 120px;
      line-height: $default-line-height;
      @include mq(tablet) {
        padding-top: 200px; } } }

  & .floating-cta {
    margin-bottom: 300px;

    @include mq(tablet) {
      .section-title--line {
        padding: 0 35px;
        &::before {
          width: 70px; } } } } }
