.client-case-hub {

  .page__content {
    display: flex;
    flex-direction: column;
 }    //height: 100vh

  &__main {
    margin: 15vh auto 0;
    padding: 15px 0;
    @include mq(tablet) {
      margin: auto; } }

  &__filters {
    padding-bottom: 20px;

    &__mobile {
      display: none;
      @include mq(tablet) {
        display: flex;
        align-items: center; }

      .filter:first-child {
        margin-bottom: 0; } }

    .icon-button {
      display: none;
      margin-left: auto;
      margin-top: 25px;
      @include mq(tablet) {
        display: flex; } }

    &__header {
      display: none;
      font-family: $alternate-font;
      font-size: rem(25);
      font-style: italic;
      text-align: center;
      margin-bottom: 8px;
      @include mq(tablet) {
        display: block;
        width: 100%; } }

    &__main {
      display: flex;
      flex-wrap: wrap;
      @include mq(tablet) {
        position: fixed;
        bottom: 0;
        left: 0;
        padding: 20px 16px;
        background-color: $light-color;
        z-index: 2;
        transition: 450ms $ease-in-out;
        transform: translateY(100%);
        @include box-shadow; }

      &.is-open {
        transform: none; } }

    &__section {
      display: flex;
      flex-wrap: wrap;

      h2 {
        display: none;
        width: 100%;
        margin-bottom: 15px;
        font-weight: $extrabold;
        font-size: rem(16);
        @include mq(tablet) {
          display: block; } }

      &:last-child {
        width: 100%; } }

    .filter {
      margin-right: 15px;
      margin-bottom: 15px; } }

  &__list {
    transition: 300ms ease;

    &.is-fetching {
      pointer-events: none;
      opacity: .4; }
    .swiper-wrapper {
      flex-wrap: wrap;
      .push-case {
        max-width: 385px;
        margin-right: 18px;
        @include mq(mobile) {
          max-width: 100%;
          width: 100%;
          margin-right: 0; } } } }

  .scrollbar {
    margin: 45px auto 0;
    max-width: 400px;
    display: none; } }
