.news {
  .page__header {
    padding-top: 220px;
    max-width: 920px;
    @include mq(mobile) {
      padding-top: 100px; }

    .tag {
      color: $medium-color;

      &--event {
        .outer {
          stroke: $primary-color; }
        .inner {
          fill: $primary-color; } } } }

  &__meta {
    display: flex;
    padding-bottom: 15px;
    color: $medium-color;
    font-weight: $extrabold;
    font-size: rem(16);
    text-transform: uppercase;
    width: 55%;
    @include mq(tablet) {
      width: 100%;
      flex-direction: column;
      padding-top: 24px; }

    &--event {
      width: 100%;
      max-width: 720px; }

    &__author {
      @include mq(mobile) {
        display: block; } } }

  &__share {
    display: flex;
    align-items: center;
    margin-left: auto;
    width: 100%;
    max-width: 100px;
    height: 20px;

    @include mq(mobile) {
      margin-left: 0;
      margin-top: 15px; }

    &__item {
      margin: 0 10px;

      &:hover {
        path {
          fill: $primary-color; } }

      @include mq(mobile) {
        &:first-child {
          margin-left: 0; } } }
    .icon {
      height: 12px;
      width: 16px; } }

  &__inner {
    margin-top: 50px;
    @include mq(tablet) {
      margin-top: 0px; }
    & > .block-container:first-child *:first-child {
      padding-top: 0; } }

  &__expert {
    display: flex;
    margin-top: 35px;
    height: 150px;
    background-color: $light-color;
    @include box-shadow;

    &__image {
      height: 100%;
      max-width: 180px;
      width: 100%;
      background-position: center top;
      background-size: cover;
      @include mq(mobile) {
        max-width: 75px; } }

    &__main {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 30px;
      @include mq(mobile) {
        flex-direction: column;
        padding: 20px 8px 12px 12px;
        justify-content: flex-start;
        align-items: flex-start;
        .link {
          margin-left: auto;
          margin-top: 16px; } } }

    &__infos {
      display: flex;
      flex-direction: column;
      height: 100%; }

    .name {
      margin-top: auto;
      font-size: rem(16);
      color: $medium-color;
      text-transform: uppercase;
      font-weight: $extrabold;
      line-height: 1.5; }

    .function {
      font-family: $alternate-font;
      font-size: rem(25);
      letter-spacing: space(10);
      line-height: 1.1; } }

  &__tags {
    display: flex;
    flex-wrap: wrap;

    .filter {
      margin-right: 15px;
      margin-top: 15px; } }

  &__footer {
    padding-top: 30px;

    .news__share {
      .icon {
        height: 16px; }
      @include mq(mobile) {
        margin-top: 0;
        .icon {
          height: 22px; } } } }

  &__credit {
    padding-top: 30px;
    font-size: rem(15);

    span {
      color: $primary-color; } }

  &__form {
    margin: 90px auto 60px;
    padding: 55px 105px 100px;
    border-radius: 10px;
    background-color: $light-color;
    @include box-shadow;
    @include mq(tablet) {
      padding: 38px 25px 30px; }

    &__inner {
      display: flex;
      @include mq(tablet) {
        flex-direction: column; } }

    &__header {
      max-width: 300px;
      @include mq(tablet) {
        width: 100%; } }

    .section-title--line {
      @include mq(tablet) {
        padding-left: 45px;
        margin-bottom: 15px;
        &::before {
          width: 100vw;
          left: initial;
          right: calc(100% - 35px); } } }

    &__infos {
      @include mq(tablet) {
        display: flex;
        align-items: center;
        margin-top: 20px; } }

    &__date {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 40px;
      width: 150px;
      height: 150px;
      border-radius: 10px;
      font-family: $alternate-font;
      font-size: rem(26);
      font-style: italic;
      text-transform: lowercase;
      color: $primary-color;
      @include box-shadow;
      @include mq(tablet) {
        margin-top: 0; }
      @include mq(mobile) {
        width: 100px;
        height: 100px;
        font-size: rem(16); }

      .day {
        font-weight: $black;
        font-size: rem(52);
        font-style: normal;
        line-height: .2;
        margin-bottom: 20px;
        @include mq(mobile) {
          font-size: rem(33); } } }

    &__address {
      display: flex;
      align-items: flex-start;
      padding-top: 20px;
      @include mq(tablet) {
        padding-top: 0;
        margin-left: 23px; }

      .icon {
        margin-top: .3em;
        @include ie11 {
          width: 18px;
          height: 25px;
          margin-top: -1px; } }


      address {
        padding-left: 10px;
        line-height: $default-line-height;
        letter-spacing: space(10); } }

    &__main {
      padding-left: 28px;
      padding-top: rem(20);
      @include mq(mobile) {
        padding-left: 0;
        padding-top: 40px; } }

    &__terms {
      line-height: $default-line-height;
      font-size: rem(15); }

    &__fields {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .field {
        width: 100%;
        max-width: calc(100% - 14px); }

      @include mq(mobile) {
        flex-direction: column;
        .field {
          max-width: 100%;
          padding-top: 40px; } } }

    &__submit {
      display: flex;
      flex-direction: column;
      align-items: flex-end; }

    &__columns {
      display: flex;
      justify-content: space-between;
      @include mq(tablet) {
        flex-direction: column; } }

    &__column {
      min-width: 320px;
      max-width: 45%;
      width: 100%;
      flex-shrink: 0;
      @include mq(tablet) {
        max-width: 100%; }
      @include mq(mobile) {
        min-width: 0; } } }

  .pushs {
    margin-top: 70px; } }

.news__study {

  &__document {
    max-width: 250px;
    width: 100%;
    flex-shrink: 0;
    margin-right: 100px;
    @include mq(tablet) {
      margin-right: 0; }

    img {
      width: 100%; } }


  &__form__required {
    font-size: rem(15);
    margin-top: 7px;
    span {
      color: $primary-color; } }

  &__submit {
    font-size: rem(17);
    padding: 4px 20px;
    margin-top: 24px;
    height: 38px; }

  .identity__expert {
    padding-top: 38px;
    @include mq(tablet) {
      padding-top: 24px; } }

  .news__form {
    padding: 50px;
    height: 100%;
    @include mq(tablet) {
      margin: 75px 16px;
      margin-top: 0;
      padding: 50px 25px; }

    h3 {
      font-family: $alternate-font;
      font-style: italic;
      font-size: rem(30); }

    &__inner {
      align-items: center; }

    &__main {
      width: 100%;
      padding: 0;
      @include mq(tablet) {
        margin-top: 30px; } }

    &__submit {
      align-items: flex-start; } } }
