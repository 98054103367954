.page-content {
  .page__header .section-title {
    margin: 100px 0 70px;
    text-align: center;

    @include mq(mobile) {
      margin: 0; } }

  .page__inner {
    padding-bottom: 50px; }

  .wysiwyg {
    line-height: 23px; } }
