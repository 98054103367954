.pushs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 65px;
  overflow: hidden;
  background-color: $medium-color-light;
  padding-bottom: 240px;
  @include mq(tablet) {
    padding: 30px 0px 80px 0px;
    margin-bottom: 0; }

  &--technologies {
    padding-bottom: 50px;
    margin-bottom: 0px; }

  .controls {
    padding-left: 50px;
    padding-right: 55px;
    @include mq(tablet) {
      padding: 0px 15px;
      top: 0px;
      z-index: 2;
      width: 100%;
      flex: 1;
      order: 2; } }

  .scrollbar {
    margin-left: 10px;
    flex: 1; }

  &__slider {
    margin: 55px auto;
    @include mq(mobile) {
      position: relative;
      margin: 20px auto; } } }
// Generic components for other swipers
.controls {
  display: flex;
  align-items: center;
  flex: 1;
  &--technologies {
    flex: none;
    width: 100%;
    max-width: 1052px;
    margin: 20px auto;
    padding-left: 0px !important; }

  &__buttons {
    button {
      margin-right: 25px;
      transition: opacity 200ms ease;

      &.swiper-button-disabled {
        opacity: .5; } }

    .icon {
      height: 20px;
      width: 10px; } } }

.scrollbar {
  position: relative;
  height: 9px;

  &::before {
    content: '';
    position: absolute;
    top: 4px;
    width: 100%;
    height: 1px;
    background-color: $dark-color; }


  // Transparent handle, we animate a before
  &__handle {
    height: 100%;

    &:hover,
    .is-dragging & {
      cursor: grab;

      &::before {
        transform: none;
        border-radius: 5px; } }

    &::before {
      content: '';
      display: block;
      margin-left: 13px;
      transform: scaleX(4) scaleY(0.5);
      width: 9px;
      border-radius: 0;
      height: 100%;
      background-color: $primary-color;
      transition: 450ms $ease-in-out; } } }

// Push types
.push-news {
  max-width: 510px;
  width: 100%;
  margin-right: 135px;
  @include mq(mobile) {
    width: 70%;
    margin-right: 15px; }


  &__image {
    @include object-fit(60%); }

  &__meta {
    padding-top: 15px;
    text-transform: uppercase;
    font-weight: $extrabold;
    color: $medium-color;
    font-size: rem(12);
    letter-spacing: space(10); } }

.push-case {
  max-width: 400px;
  width: 100%;
  margin-right: 28px;
  @include mq(mobile) {
    width: 70%;
    margin-right: 15px; }


  &--large {
    max-width: calc(50% - 14px); }

  &__image {
    @include object-fit(45%); } }

.push-technology {
  width: 186px;
  height: 186px;
  max-width: 186px;
  max-height: 186px;
  background: #FFFFFF;
  margin-right: 20px;

  .technology__image {
    width: 100%;
    height: 100%;
    max-width: 186px;
    max-height: 186px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;

    img {
      width: 80%;
      max-width: 80%;
      max-height: 80%;
      object-fit: contain; } } }

.pushs__title--technologies {
  font-size: rem(57);
  @include mq(mobile) {
    font-size: 7.6vw;
    line-height: 1.28;
    margin-left: 0;
    padding: 0px;
    width: 100%;
    padding: 0px 0px 0px 50px; } }
