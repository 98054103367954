.job-offer-hub {

  &__inner {
    padding: 55px 0; }

  &__main {
    padding-top: 30px;
    transition: opacity 300ms ease;

    &.is-fetching {
      opacity: .4; } }

  &__total {
    line-height: $default-line-height;
    border-bottom: 1px solid $dark-color; }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include mq(mobile) {
      flex-direction: column; }

    &::after {
      content: '';
      max-width: calc(33% - 14px);
      width: 100%; } }

  &__more {
    margin: 48px auto 0; }

  .floating-cta {
    margin-bottom: 180px; } }

.offer {
  position: relative;
  max-width: calc(33% - 14px);
  width: 100%;
  height: 180px;
  margin-top: 28px;
  padding: 12px 20px;
  padding-right: 80px;
  border-radius: 4px;
  transform: translateY(25px);
  opacity: 0;
  animation: cancel-transform 0ms $ease-in-out forwards;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 20px;
  overflow: hidden;
  @include box-shadow;
  @include mq(tablet) {
    max-width: 100%;
    height: auto;
 }    //min-height: 160px

  &.is-new {
    animation: cancel-transform 450ms $ease-in-out forwards; }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 33px;
    width: 20px;
    height: 20px;
    border-radius: 13px;
    transform: translate3d(0, -50%, 0);
    transition: 350ms $ease-in-out;
    background-color: $primary-color; }

  &::after {
    width: 26px;
    height: 26px;
    right: 30px;
    border: 1px solid $primary-color;
    background-color: transparent;
    transition-delay: 75ms; }

  &:hover {
    &::before {
      transform: translate3d(0, -50%, 0) scale(1.2); }
    &::after {
      transform: translate3d(0, -50%, 0) scale(1.3); } }

  &--data-led-strategy {
    &::before {
      background-color: $strategy-color; }
    &::after {
      border-color: $strategy-color; } }

  &--data-science-ai {
    &::before {
      background-color: $science-color; }
    &::after {
      border-color: $science-color; } }

  &--digital-acceleration {
    &::before {
      background-color: $digital-color; }
    &::after {
      border-color: $digital-color; } }

  &--data-governance-engineering {
    &::before {
      background-color: $engineering-color; }
    &::after {
      border-color: $engineering-color; } }

  &--digital-performance {
    &::before {
      background-color: $performance-color; }
    &::after {
      border-color: $performance-color; } }

  &__title {
    font-family: $alternate-font;
    font-size: rem(25);
    line-height: 1.1; }

  &__meta {
    display: inline-block;
    padding-top: 5px;
    text-transform: uppercase;
    color: $medium-color;
    font-weight: $extrabold;
    font-size: rem(12);
    letter-spacing: space(10); }

  &__hub__description {
    line-height: 1.3;
    margin-top: 20px;
    overflow: hidden;
    text-overflow: hidden;
    @include mq(mobile) {
      padding: 0;
      margin-top: 0px; } }

  a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; } }
