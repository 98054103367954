/**
  * Colors
  */
$light-color: #fff !default;
$dark-color: #1D1D1D !default;
$medium-color: #A8A8A8 !default;
$medium-color-light: #F5F4F3 !default;
$medium-color-dark: #888888 !default;

// Expertises
$strategy-color: #864593 !default;
$science-color: #5D52DD !default;
$engineering-color: #DF005F !default;
$digital-color: #0FA5E8 !default;
$performance-color: #E810CF !default;

$primary-color: $engineering-color !default;

/**
  * Font weights
  */
$light: 300 !default;
$regular: normal !default;
$extrabold: 800 !default;
$black: 900 !default;

/**
  * Default font-stack
  */
$body-font: 'Gilroy', apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif !default;
$alternate-font: 'Playfair Display', apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', serif !default;
$default-line-height: 1.47 !default; // 25 / 17

/**
 * Shadows
 */
$default-box-shadow: 0 3px 36px rgba(0, 0, 0, .12);

/**
 * Easings
 */
$ease-in-out: cubic-bezier(.785, .135, .15, .86);

/**
 * Widths
 */
$wrapper-width: 1042px;
$wrapper-width-large: 1252px;

/**
  * Variable maps
  */
// Use with z-index function, e.g. z(base)
$z-indexes: (base: 0, content: 2, nav: 4, overlay: 6, cookies: 8 ) !default;
// Use with mq mixin
// +mq(tablet)
//    ...rules
$breakpoints: (desktopL: 'screen and (min-width: 1240px)', desktop: 'screen and (max-width: 1240px)', desktopS: 'screen and (max-width: 1080px)', tablet: 'screen and ( max-width: 1030px )', mobile: 'screen and (max-width: 700px )') !default;
