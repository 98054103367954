.expertise {
  &-detail {
    &__section {
      padding: 50px 0px 5px 0px;
      min-height: 440px;
      @include mq(mobile) {
        display: flex;
        flex-direction: column; }
      &--white {
        background: $light-color; }
      &--grey {
        background: $medium-color-light; } }
    // &__wrapper
    //   .section-title--line
    //     margin-left: 0px

    .client-case-card {
      width: 100%;
      max-width: 400px;
      &__inner {
        width: 100%; }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain; }
      &__title {
        font-size: rem(25);
        font-family: $alternate-font;
        font-style: normal;
        margin-bottom: 5px; }
      &__description {
        font-size: rem(17);
        font-family: $body-font;
        font-style: normal; } }

    &__hero__tag__list {
      display: flex;
      margin: 30px 0px 0px 0px;
      flex-wrap: wrap; }

    &__hero__tag {
      margin: 0px 10px 0px 0px;
      @include mq(mobile) {
        padding: 5px 10px;
        margin: 0px 15px 15px 0px; } }

    &__quotes {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px 30px;
      border-radius: 10px;
      background-image: url('/img/quote.svg');
      background-repeat: no-repeat;
      background-position: top 10px left 10px;
      font-family: $alternate-font;
      line-height: 1.2;
      text-align: center;
      letter-spacing: space(10);
      font-size: rem(25);
      @include box-shadow;
      width: 100%;
      max-width: calc(33% - 14px);
      min-height: 180px;
      @include mq(mobile) {
        max-width: 100%;
        min-height: 100%;
        padding: 35px 30px;
        margin-bottom: 20px; }
      &__wrapper {
        display: flex;
        justify-content: space-between;
        margin: 80px auto;
        flex-wrap: wrap;
        width: calc(100% - 32px);
        @include mq(mobile) {
          margin: 30px auto; } } }

    &__wrapper {
      width: calc(100% - 32px);
      max-width: 1052px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 0px auto;
      @include mq(mobile) {
        width: calc(100% - 30px); }
      & article {
        @include ie11 {
          height: 100%; } }

      &__full {
        width: calc(100% - 32px);
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        @include mq(mobile) {
          width: calc(100% - 30px);
          margin: 0px auto; }
        @include mq(mobile) {
          flex-direction: column; }
        &--key-numbers {
          @include mq(mobile) {
            order: 2; } } }
      &__half {
        display: flex;
        flex-direction: column;
        margin-bottom: 80px;
        @include mq(mobile) {
          width: 100%;
          margin-bottom: 40px; }
        &--known {
          @include mq(mobile) {
            order: 1; } }
        &--intervention {
          @include mq(mobile) {
            order: 3; } }
        &--expert-card {
          @include mq(mobile) {
            order: 4; } }
        &--client-case {
          position: relative;
          @include mq(mobile) {
            order: 5; } }
        &--60 {
          width: 63%;
          max-width: 615px;
          height: 100%;
          @include mq(mobile) {
            width: 100%; } }
        &--30 {
          width: 35%;
          max-width: 400px;

          @include mq(mobile) {
            width: 100%; } } } }

    &__key-numbers {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px 30px;
      border-radius: 10px;
      background-image: url('/img/01.svg');
      background-repeat: no-repeat;
      background-position: top 10px left 10px;
      font-family: $alternate-font;
      line-height: 1.2;
      text-align: center;
      letter-spacing: space(10);
      font-size: rem(25);
      @include box-shadow;
      width: 100%;
      max-width: calc(33% - 14px);
      min-height: 180px;
      background-color: $light-color;
      @include mq(mobile) {
        max-width: 100%;
        margin-bottom: 20px; }
      &--1 {
        background-image: url('/img/01.svg'); }
      &--2 {
        background-image: url('/img/02.svg'); }
      &--3 {
        background-image: url('/img/03.svg'); }
      &__wrapper {
        display: flex;
        justify-content: space-between;
        margin: 80px auto;
        @include mq(mobile) {
          margin: 30px auto; } } }

    &__text {
      margin-top: 40px;
      max-width: 507px;
      line-height: 1.3;
      @include mq(mobile) {
        margin-top: 20px; } }

    &__offer {
      width: 100%;
      max-width: calc(50% - 20px);
      font-family: $alternate-font;
      font-size: rem(25);
      min-height: 162px;
      padding: 18px;
      padding-right: 75px;
      line-height: 1.4;
      position: relative;
      @include mq(mobile) {
        width: 100%;
        max-width: 100%; }

      &__link {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 2; }

      &__wrapper {
        display: flex;
        justify-content: space-between;
        margin-top: 50px;
        @include mq(mobile) {
          flex-direction: column; } } } } }



.pushs__slider {
  &--technologies {} }

