.block-text-image {
  display: flex;
  align-items: flex-start;
  padding-top: 45px;
  @include mq(mobile) {
    flex-direction: column; }

  img {
    width: 100%;
    height: 100%;
    max-width: 345px;
    max-height: 345px;
    object-fit: cover;
    @include ie11 {
      height: 345px;
      min-width: 345px;
      font-family: 'object-fit: cover'; } // Hack Object-fit for IE 11

    &.left {
      margin-right: 28px; }
    &.right {
      margin-left: 28px; }
    @include mq(mobile) {
      max-width: 100%;
      &.left, &.right {
        margin: 0; } } }

  &__content {
    p:first-child {
      padding-top: 0; }

    h1, h2, h3, h4, h5, h6 {
      padding-bottom: 15px;
      font-family: $alternate-font;
      font-size: rem(25);
      letter-spacing: space(10);
      color: $primary-color;
      line-height: 1.1; } } }
