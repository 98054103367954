.locale-switcher {
  position: relative;
  color: $dark-color;
  @include mq(tablet) {
    margin-top: 20px;
    font-size: rem(22); }

  &__current {
    text-transform: uppercase;
    transition: 300ms $ease-in-out;
    @include mq(tablet) {
      color: $light-color; }

    .header--light & {
      color: $light-color; }

    .is-solid & {
      color: $dark-color; } }

  &__list {
    position: absolute;
    top: 100%;
    left: 50%;
    opacity: 0;
    pointer-events: none;
    padding: 10px;
    background-color: $light-color;
    transform: translate3d(-50%, -15px, 0);
    opacity: 0;
    transition: 300ms $ease-in-out;
    border-radius: 4px;
    @include box-shadow;

    .is-active & {
      opacity: 1;
      pointer-events: all;
      transform: translate3d(-50%, 5px, 0); } }

  &__item {
    text-transform: uppercase;

    a {
      text-decoration: none;
      color: inherit; } } }
