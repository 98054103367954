.identity {

  &__intro {
    padding-top: 45px;
    display: flex;
    align-items: flex-start;
    @include mq(tablet) {
      flex-direction: column;
      width: calc(100vw - 30px);
      margin: 0px auto;
      padding-top: 20px; }

    &__image {
      width: 50%;
      box-shadow: 3px 3px 12px rgba(0, 0, 0, .12);
      @include mq(tablet) {
        width: 100%; } }

    &__content {
      margin-left: 28px;
      max-width: 360px;
      @include mq(tablet) {
        margin: 0;
        max-width: 100%; } } }

  &__expert {
    display: flex;
    padding: 100px 0;
    flex-wrap: wrap;
    @include mq(tablet) {
      padding: 40px 0px 70px 0px; }
    @include mq(mobile) {
      padding-bottom: 40px; }

    &__quote {
      flex: 1;
      display: flex;
      align-items: center;
      padding: 18px;
      border-radius: 10px;
      background-image: url('/img/quote.svg');
      background-repeat: no-repeat;
      background-position: top 10px left 10px;
      font-family: $alternate-font;
      line-height: 1.667;
      text-align: center;
      letter-spacing: space(10);
      font-size: rem(18);
      @include box-shadow;
      @include mq(tablet) {
        margin-bottom: 40px; } }

    .expert-card {
      margin-left: 28px;
      @include mq(mobile) {
        margin: 0px; }

      &__title {
        display: none; }

      &__inner {
        margin-top: 0; } } }

  &__main {
    padding-top: 60px;
    background-color: $medium-color-light;
    padding-bottom: 42%;
    background-image: url('/img/paris.png');
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: contain; }

  &__keywords {
    display: flex;
    justify-content: space-between;
    padding-top: 70px;
    margin: 0 -14px;
    @include mq(tablet) {
      flex-direction: column;
      height: 100%;
      min-height: 800px;
      margin: 0px;
      padding: 40px 0px;
      justify-content: flex-start;
      pointer-events: none; } }

  &__keyword {
    position: relative;
    flex: 1;
    margin: 0 14px;
    height: 200px;
    background-color: $light-color;
    border-radius: 10px;
    transition: 500ms ease;
    @include box-shadow;
    @include mq(tablet) {
      height: 180px;
      margin: 0px 0px 20px 0px;
      pointer-events: none; }

    &__content {
      position: absolute;
      top: 40%;
      left: 0;
      width: 100%;
      text-align: center;
      transition: 500ms $ease-in-out;
      @include mq(tablet) {
        top: 40px;
        pointer-events: none; } }

    &__title {
      position: relative;
      font-family: $alternate-font;
      font-size: rem(38);
      letter-spacing: space(-10);
      font-style: italic;
      @include mq(tablet) {
        font-size: rem(25);
        margin: 0px 0px 20px 0px; }

      &::after {
        position: absolute;
        bottom: -10px;
        left: 50%;
        content: '';
        display: block;
        width: 40px;
        height: 2px;
        background-color: $primary-color;
        transform: translate3d(-50%, 0 , 0) scaleX(0);
        transition: transform 500ms $ease-in-out;
        @include mq(tablet) {
          transform: none;
          width: 25px;
          left: calc(50% - 12.5px);
          bottom: -20px; } } }

    .icon {
      position: absolute;
      bottom: 18px;
      left: calc(50% - 10px);
      width: 20px;
      transition: 500ms $ease-in-out;
      @include ie11 {
        bottom: 0px; }
      @include mq(tablet) {
        display: none; } }

    &__desc {
      padding: 15px 28px;
      line-height: $default-line-height;
      opacity: 0;
      transition: 300ms $ease-in-out;
      transform: scale(.9);
      @include mq(tablet) {
        transform: none;
        opacity: 1; } }

    &:hover {
      transform: translateY(-10px);

      .identity__keyword__content {
        transform: translateY(-60px);
        @include mq(tablet) {
          transform: none; } }

      .identity__keyword__title {
        &::after {
          transform: translate3d(-50%, 0 , 0) scaleX(1);
          @include mq(tablet) {
            transform: none; } } }

      .identity__keyword__desc {
        opacity: 1;
        transform: none; }

      .icon {
        transform: rotate(180deg); } } }

  &__stats {
    display: flex;
    justify-content: flex-start;
    padding-top: 90px;
    @include mq(tablet) {
      flex-direction: column;
      padding-top: 20px; }

    &__floating {
      padding-left: 44px;
      padding-right: 28px;
      flex: 1;
      @include mq(tablet) {
        padding: 0px 0px 50px 0px; } }

    .floating-number {
      margin-bottom: 35px;
      @include mq(mobile) {
        margin-bottom: 25px; }
      &:last-child {
        margin-bottom: 0; } }

    &__cards {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-content: flex-start;
      margin-bottom: auto;
      height: auto;
      @include ie11 {
        max-height: 160px; } }

    .card-number {
      width: calc(50% - 14px);
      margin-top: 15px;
      @include mq(mobile) {
        width: calc(100% - 40px);
        margin: 0px auto 15px auto; } } }

  &__map {

    &__wrapper {
      position: relative;
      margin: 120px 0px -100px 0px;
      @include mq(tablet) {
        margin: 0px; } }

    &__cards {
      @include mq(mobile) {
        display: flex;
        flex-direction: column;
        width: calc(100% - 30px);
        margin: 30px auto 0px auto; } }
    &__card {
      pointer-events: none;
      position: absolute;
      display: inline-block;
      padding: 18px 24px;
      border-radius: 4px;
      background-color: $light-color;
      max-width: 265px;
      width: 100%;
      opacity: 0;
      transform: translateY(20px) scale(.95);
      transition: 500ms $ease-in-out;
      @include box-shadow;
      @include mq(mobile) {
        opacity: 1;
        position: static;
        width: 100%;
        max-width: 100%;
        transform: none;
        margin-bottom: 20px; }

      &.is-visible {
        opacity: 1;
        transform: none; }

      .city {
        font-size: rem(30);

        strong {
          font-weight: $extrabold; } }

      address {
        padding-top: 5px;
        font-size: rem(14);
        letter-spacing: space(10);
        line-height: 1.5; } }

    &__illu {
      width: 100%;
      @include mq(mobile) {
        margin-top: 0px;
        width: 100%;
        height: 200px;
        overflow: hidden; } }

    [data-map-location] {
      position: relative;
      z-index: 2;
      cursor: pointer;

      &:hover {
        .scale-dot {
          transform: scale(2);
          fill: rgba($medium-color, .2); } } }

    ellipse:not(.main-dot) {
      fill: transparent; }

    .scale-dot {
      transform-origin: 20px 10px;
      transition: 600ms $ease-in-out; } }

  &__notice {
    padding: 90px 0 35px;
    line-height: $default-line-height; }

  .swiper-wrapper {
    @include mq(tablet) {
      flex-wrap: wrap; }
    .push-news {
      @include mq(tablet) {
        width: 100%;
        margin-bottom: 35px; } } }

  &__edg {
    &__content {
      background-color: $medium-color-light;
      padding-top: 65px;
      padding-bottom: 65px;
      .wrapper--large {
        margin: 65px auto 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 50px;
        align-items: center;
        @include mq(tablet) {
          grid-template-columns: repeat(1, 1fr); }

        p {
          line-height: 1.4rem; }
        & > div {
          a {
            color: $digital-color;
            &:hover {
              pointer-events: all; }
            img {
              width: 100%; } } } } }

    .waves-top {
      padding-bottom: 250px;
      @include mq(mobile) {
        padding-bottom: 115px; } } } }
