.client-case {
  padding-top: 240px;
  @include mq(tablet) {
    padding-top: 100px; }

  &__name {
    display: flex;
    align-items: center;
    @include mq(mobile) {
      flex-direction: column;
      align-items: flex-start; }
    .subtitle {
      padding-bottom: 0;
      font-weight: normal;
      @include mq(mobile) {
        padding-bottom: 5px; }
      &-mobile {
        @include mq(mobile) {
          display: none; } } }
    .name {
      font-size: 1rem;
      text-transform: uppercase;
      font-family: $body-font; } }
  &__infos {
    &__content {
      width: 100%; } }
  &__hero {
    position: relative;
    padding-bottom: 120px;
    @include mq(tablet) {
      padding-bottom: 50px; }

    &__tags {
      display: flex;
      flex-wrap: wrap;
      padding-top: 30px;
      @include mq(tablet) {
        padding-top: 10px; }

      .filter {
        margin-right: 15px;
        margin-top: 15px; } }

    &__logo {
      position: absolute;
      bottom: 0;
      right: -40px;
      width: 40%;
      z-index: -1;
      opacity: 0.15;
      @include mq(tablet) {
        margin-top: 20px;
        position: static;
        opacity: 1; } } }

  &__testimony {
    padding-bottom: 95px;
    &.join-us__videos {
      padding-top: 0; }
    .video {
      &-thumb {
        margin: 0 auto; } } }

  &__intro {
    display: flex;
    align-items: flex-start;
    @include mq(tablet) {
      flex-direction: column; }

    &__image {
      width: 50%;
      @include mq(tablet) {
        width: 100%;
        max-width: calc(100% - 30px);
        margin: 0 auto;
        border-radius: 5px;
        overflow: hidden;
        @include box-shadow; } }

    &__content {
      display: flex;
      flex-direction: column; }

    .section-title {
      padding-top: 20px;
      transform: translateX(-80px);
      @include mq(tablet) {
        transform: none;
        font-size: rem(25); } }

    .wysiwyg {
      padding-top: 40px;
      max-width: 400px;
      margin-left: auto;
      @include mq(tablet) {
        padding-top: 0;
        margin-left: 0; } } }

  &__goals {
    padding-top: 70px;

    .section-title {
      text-align: center; }

    &__inner {
      display: flex;
      justify-content: center;
      padding-top: 70px;
      @include mq(tablet) {
        padding-top: 0;
        flex-direction: column; } } }

  &__goal {
    max-width: 240px;
    width: 100%;
    margin: 0 40px;
    @include mq(tablet) {
      margin: 8px 0px;
      max-width: 100%;
      text-align: center; }

    &__number {
      font-weight: $black;
      font-family: $alternate-font;
      font-size: rem(120);
      color: $primary-color;
      @include mq(tablet) {
        font-size: rem(90); } }

    &__desc {
      padding-top: 25px;
      padding-left: 50px;
      line-height: 1.7;
      letter-spacing: space(10);
      @include mq(tablet) {
        padding-left: 0px; } } }

  // Client case general list for deliverables and results
  &__list {
    margin-top: 30px;
    padding: 32px;
    border-radius: 8px;
    background-color: $light-color;
    display: flex;
    flex-wrap: wrap;
    @include box-shadow;
    @include mq(tablet) {
      flex-direction: columns; }

    &__item {
      display: flex;
      align-items: flex-start;
      width: 50%;
      padding-right: 25px;
      line-height: 1.25;
      @include mq(tablet) {
        padding-top: 1rem;
        width: 100%; }

      &:first-child {
        padding-top: 0; }

      &::before {
        display: block;
        flex-shrink: 0;
        margin-top: .6rem;
        margin-right: 25px;
        content: '';
        width: 5px;
        height: 5px;
        border-radius: 3px;
        background-color: $primary-color;
        transform: translateY(-50%); } } }


  &__deliverables {
    margin-top: 70px;
    padding: 70px 0;
    background-color: $medium-color-light;
    @include mq(tablet) {
      margin-top: 40px;
      padding: 40px 0; }

    &__inner {
      display: flex;
      @include mq(tablet) {
        flex-direction: column; } }

    &__main {
      flex: 1;
      margin-right: 30px;
      @include mq(tablet) {
        margin-right: 0; } }

    .expert-card {
      align-self: flex-end;
      @include mq(tablet) {
        margin-top: 40px; } } }

  &__results {
    background-color: $medium-color-light;
    padding-bottom: 70px;
    @include mq(tablet) {
      padding-bottom: 40px; }

    .client-case__list {
      max-width: 930px;
      @include mq(tablet) {
        max-width: 100%;
        flex-direction: column; }

      &__item {
        width: 33%;
        @include mq(tablet) {
          width: 100%;
          padding-right: 0; } } } }

  &__pushs {

    .pushs {
      background-color: $light-color;
      margin-bottom: 0;
      padding-bottom: 0; }

    .push-case {
      max-width: calc((1252px / 2) - 15px); } }

  &__video {
    padding-bottom: 70px; }
  &__youtube {
    padding-bottom: 66.58%;
    position: relative;

    iframe {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%; } } }
