.floating-number {
  position: relative;

  .number {
    font-family: $alternate-font;
    font-size: rem(120);
    line-height: 0.2; // We cancel the huge Playfair Display line-height
    font-weight: $black;
    letter-spacing: space(-10);
    text-shadow: 0 3px 50px rgba(0, 0, 0, .1);
    color: $light-color;
    @include mq(mobile) {
      font-size: rem(93); } }

  .text {
    position: absolute;
    top: 50%;
    left: rem(60);
    font-family: $alternate-font;
    font-style: italic;
    font-size: rem(38);
    letter-spacing: space(-10);
    transform: translateY(-50%);
    @include mq(mobile) {
      font-size: rem(25); } } }

.card-number {
  display: inline-block;
  padding: 22px 26px;
  background-color: $light-color;
  border-radius: 10px;
  @include box-shadow;

  .number {
    font-family: $alternate-font;
    color: $primary-color;
    font-size: rem(52);
    font-weight: $black;
    letter-spacing: space(10); }

  .text {
    padding-top: 10px;
    font-size: rem(17); } }
