.filters {
  padding-bottom: 20px;

  &__mobile {
    display: none;
    @include mq(tablet) {
      align-items: center;
      display: flex; }

    .filter:first-child {
      margin-bottom: 0; } }

  .more-button {
    margin-left: 10px; }

  .icon-button {
    margin-left: auto; }

  &__panel {
    display: flex;
    flex-wrap: wrap;
    @include mq(tablet) {
      position: fixed;
      bottom: 0;
      left: 0;
      padding: 20px 16px;
      background-color: $light-color;
      z-index: 10;
      transition: 450ms $ease-in-out;
      transform: translateY(100%);
      @include box-shadow; }

    &.is-open {
      transform: none;
      @include mq(mobile) {
        position: absolute;
        top: 0;
        bottom: inherit; }

      &::after {
        visibility: visible;
        pointer-events: all;
        opacity: 1; } }

    &::after {
      content: '';
      position: absolute;
      height: 100vh;
      background-color: transparentize($dark-color, .1);
      width: 100%;
      bottom: 100%;
      left: 0;
      visibility: hidden;
      pointer-events: none;
      opacity: 0;
      transition: 450ms $ease-in-out; }

    &__header {
      display: none;
      width: 100%;
      margin-bottom: 15px;
      font-family: $alternate-font;
      font-size: rem(25);
      font-style: italic;
      text-align: center;
      @include mq(tablet) {
        display: block; } }

    &__section {
      display: flex;
      flex-wrap: wrap;
      @include mq(tablet) {
        width: 100%; }

      h2 {
        display: none;
        width: 100%;
        margin-bottom: 15px;
        font-weight: $extrabold;
        font-size: rem(16);
        @include mq(tablet) {
          display: block; } }

      &:last-child {
        width: 100%; } }

    .filter {
      margin-right: 15px;
      margin-bottom: 15px; }

    &__footer {
      display: none;
      width: 100%;
      align-items: center;
      margin-top: 25px;
      @include mq(tablet) {
        display: flex; }

      .more-button {
        &::before {
          background-image: url('/img/less.svg');
          background-position: center;
          transform: none; }

        &::after {
          transform: none; } } } } }

.filter {
  padding: 5px 20px;
  background-color: $light-color;
  border-radius: 3px;
  border: 1px solid transparent;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, .16);
  font-size: rem(14);
  line-height: 1.2;
  letter-spacing: space(10);
  transition: 200ms ease;

  &--disabled {
    pointer-events: none; }

  &:hover {
    color: $primary-color; }

  &:not(.is-active):active {
    border-color: #E4E4E4;
    border: 1px solid $medium-color;
    box-shadow: none; }

  &.is-active {
    background-color: $primary-color;
    color: $light-color; } }
