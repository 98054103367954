.floating-cta {
  position: relative;
  margin: 90px auto;
  padding: 50px 0;
  border-radius: 10px;
  @include box-shadow;

  &__animation-top {
    position: absolute;
    bottom: 99%;
    right: 5%;
    width: 50%;
    @include ie11 {
      bottom: 93%; }
    @include mq(tablet) {
      width: 100%; } }

  &__animation-bottom {
    position: absolute;
    top: 100%;
    left: 0;
    width: 50%;
    @include ie11 {
      top: 93.5%; }
    @include mq(tablet) {
      width: 100%; } }

  &__header {
    max-width: 650px;
    width: 100%;
    margin: 0 auto;
    text-align: center; }

  &__intro {
    padding-top: 10px;
    line-height: $default-line-height; }

  .section-title {
    @include mq(tablet) {
      padding: 0px 60px; }
    &::before {
      width: 50vw;
      @include mq(tablet) {
        left: -15px;
        width: 75px; } } }

  &__links {
    text-align: center;

    .link,
    .icon-button {
      margin: 50px 25px 0;
      @include mq(mobile) {
        margin: 20px 25px 0; } } } }
