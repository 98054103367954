.video-thumb {
  position: relative;
  max-width: 290px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  overflow: hidden;
  transition: 400ms $ease-in-out;

  &:hover {
    transform: scale(.95); }

  button {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; }

  &__inner {
    padding-top: 65%;
    @include mq(tablet) {
      max-width: calc(50% - 10px); } }

  &__infos {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 15px 30px;
    padding-top: 15%;
    color: $light-color;
    letter-spacing: space(10);
    z-index: 0;
    background-image: linear-gradient(to top, rgba(#000, .5), transparent);
    @include mq(mobile) {
      padding: 0 0 10px 10px; } }

  .icon {
    width: 30px;
    margin-right: 15px;
    @include mq(mobile) {
      min-width: 30px;
      min-height: 30px; } }

  .subtitle {
    display: block;
    padding-bottom: 8px;
    font-size: rem(16);
    font-weight: $extrabold;
    text-transform: uppercase; }

  .name {
    display: block;
    font-family: $alternate-font;
    font-size: rem(20); }

  .job {
    display: block;
    padding-top: 5px;
    font-weight: $light;
    font-size: rem(12); }

  &--large {
    max-width: 720px;

    .video-thumb__inner {
      padding-top: 56.25%; }

    .icon {
      width: 50px;
      margin-right: 28px; }

    .name {
      font-size: rem(28); }

    .job {
      font-size: rem(17); } } }
