/*
 * Media query
 */

@mixin mq($size) {
	@if map-has-key($breakpoints, $size) {
		$breakpoint: map-get($breakpoints, $size);
		@media #{$breakpoint} {
			@content; } }
	@else {
		$breakpoint: $size;
		@media #{$breakpoint} {
			@content; } } }


@mixin ie11 {
  @media all and (-ms-high-contrast:none) {
     @content; } }

/*
 * Z-index
 */

@function z($layer) {
  @if not map-has-key($z-indexes, $layer) {
    @error 'There is no `#{$layer}` key in the $z-indexes map'; }

  @return map-get($z-indexes, $layer); }

/*
 * REM
 */

$browser-font-size: 16;

@function rem($pixels, $context: $browser-font-size) {
  @return #{$pixels / $context}rem; }

/*
 * https://community.adobe.com/t5/adobe-xd/units-for-character-spacing/td-p/8835008?page=1
 */
@function space($spacing) {
  @return #{$spacing / 1000}em; }


@mixin box-shadow($blur: 36px) {
  box-shadow: 0 3px $blur rgba(0, 0, 0, .12); }

// Same as object-fit: cover but usable in IE11 too
@mixin object-fit($ratio) {
  position: relative;
  padding-top: $ratio;
  overflow: hidden;

  img {
    position: absolute;
    width: 105%;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    transition: 200ms ease; } }

@mixin placeholder($color, $focus-color: $color) {
  &:-ms-input-placeholder {
    color: $color; }
  &::-webkit-input-placeholder {
    color: $color; }
  &::placeholder {
    color: $color;
    opacity: 1; }

  &:focus:-ms-input-placeholder {
    color: $focus-color; }
  &:focus::-webkit-input-placeholder {
    color: $focus-color; }
  &:focus::placeholder {
    color: $focus-color; } }
